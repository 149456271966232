import HomePageCategory from './homepageCategory';
import categorySidingImg from '../../../img/category-siding.jpeg';
import categoryRoofingImg from '../../../img/category-roofing.jpeg';
import categoryHousewrapImg from '../../../img/category-housewrap-insulation.jpeg';
import categoryMillworkImg from '../../../img/category-millwork-trim.jpeg';
import categoryGutterImg from '../../../img/category-gutter.jpeg';
import categoryPoolPatioImg from '../../../img/category-pool-patio.png';
import categoryDeckingImg from '../../../img/category-decking.jpeg';
import categoryRailingImg from '../../../img/category-railing.jpeg';
import categoryShuttersImg from '../../../img/category-shutters.jpeg';
import categorySkylightsImg from '../../../img/category-skylights.jpeg';
import categoryStormDoorsImg from '../../../img/category-storm-doors.jpeg';
import categoryWindowsImg from '../../../img/category-windows.jpeg';
import categoryEquipmentImg from '../../../img/category-equipment-tools.jpeg';
import categoryCaulkingImg from '../../../img/category-caulking-sealant-adhesives.jpeg';
import categoryFastenersImg from '../../../img/category-fasteners.jpeg';
import { useEffect, useState } from 'react';
import dataService from '../../services/dataService';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import categoryRenaissanceImg from '../../../img/category-renaissance.jpg';

const categoryImages = {
    siding: categorySidingImg,
    roofing: categoryRoofingImg,
    housewrap: categoryHousewrapImg,
    millwork: categoryMillworkImg,
    gutter: categoryGutterImg,
    pool: categoryPoolPatioImg,
    decking: categoryDeckingImg,
    railing: categoryRailingImg,
    shutters: categoryShuttersImg,
    skylights: categorySkylightsImg,
    storm: categoryStormDoorsImg,
    windows: categoryWindowsImg,
    equipment: categoryEquipmentImg,
    caulking: categoryCaulkingImg,
    fasteners: categoryFastenersImg
};

const HomePageCategories = () => {

    let [categories, setCategories] = useState<string[]>([]);
    const warehouse = useSelector((state: RootState) => state.warehouseState.selectedWarehouse);

    useEffect(() => {
        const fetchFeaturedCategories = async () => {
            const result = await dataService.getFeaturedCategories(warehouse.warehouseCode);
            setCategories(result.data);
        };

        fetchFeaturedCategories();
    }, []);    
    return (
        <>
        
            {
            categories.map(category => {
            let categoryImage = categoryImages[category.split(' ')[0].toLowerCase().replace(',', '') as keyof typeof categoryImages];
            return (
                <HomePageCategory 
                key={category}
                categoryName={category} 
                categoryImage={categoryImage} />
            );
            })
            }
        </>
    );  
}

export default HomePageCategories;