import React from "react";
import { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Error404Page from "../../pages/errors/404";
import '@builder.io/widgets';

interface BuilderIOSectionProps {
  section: string;
  data?: any; //should contain the data for the page
  context?: any; //should contain actions (functions) that can be called in the page
  onStateChange?: (state: any) => void,
  filter?: {
    name: string,
    value: string
  },
}

const BuilderIOSection: React.FC<BuilderIOSectionProps> = ({ section, data, context, filter, onStateChange }) => {
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isPreviewingInBuilder = useIsPreviewing();

  const isAuthenticatedFromStore = useSelector((state: RootState) => state.userState.isAuthenticated);
  const [isAuthenticated, setIsAuthenticated] = React.useState(isAuthenticatedFromStore);

  const stateChangedHandler = (state: any) => {
    setIsAuthenticated(state.isAuthenticated);

    if (onStateChange) {
      onStateChange(state);
    }
  };

  useEffect(() => {
    async function fetchContent() {
      setIsLoading(true);
      try {
        const content = await builder
          .get(section, {
            userAttributes: {
              urlPath: window.location.pathname,
            },
            ...(filter?.name && filter?.value ? { query: { [filter.name]: filter.value } } : {}),
          })
          .promise();

        setContent(content);
        setNotFound(!content);
      } catch (error) {
        setNotFound(true);
      } finally {
        setIsLoading(false);
      }
    }
    fetchContent();
  }, [section, data, context]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (notFound && !isPreviewingInBuilder) {
    return <Error404Page />;
  }

  return (
    <>
      {/* Render the Builder section */}
      <BuilderComponent
        model="section"
        context={context ? { ...context } : undefined}
        content={content!}
        onStateChange={stateChangedHandler}
        data={{
          ...data,
          isAuthenticated: isAuthenticated,
          apiUrl: process.env.REACT_APP_STOREFRONT_API_URL?.replace("https://", ""),
          webUrl: process.env.REACT_APP_WEB_URL?.replace("https://", "")
        }} />
    </>
  );
};

export default BuilderIOSection;
