import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, Radio } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CustomerUser } from '../../models/customer/types';
import dataService from '../../services/dataService';


const HomepageSecondaryUser: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [customerUserData, setCustomerUserData] = useState<CustomerUser>();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const getCustomerUserData = async () => {
            await getSecondaryUserInfo()
        };
        getCustomerUserData();
    }, []); 
    
    const getSecondaryUserInfo = async () => {
        const response = await dataService.getSecondaryUserInfo();
            if (response.success === true) {    
                var data = response.data;
                setCustomerUserData(data);                                    
             }
             else {
                console.error('Error retrieving secondary user info');
            }
    }

    return (
        <>
            <div className="secondaryUserBanner" onClick={handleOpen} style={{ cursor: 'pointer' }}>
                You are a secondary user - Click here to see your current permissions »
            </div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" sx={{ zIndex: 1500 }} >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    outline: 'none',
                    zIndex: 1501
                }}>
                    <Typography variant="h6" component="h2" sx={{mb:3}}>
                        Your Current Permissions Include:
                    </Typography>

                    {customerUserData?.availableCustomerRoles && customerUserData?.availableCustomerRoles.length > 0 &&
                    <FormGroup sx={{ pl: 10 }}>
                        {customerUserData.availableCustomerRoles.map((role) => (
                            <FormControlLabel
                                control={<Checkbox defaultChecked={role.selected} disabled />}
                                label={role.text}
                            />
                        ))}
                    </FormGroup>
                    }

                    {customerUserData?.savedCustomerShipToes && customerUserData?.savedCustomerShipToes.length > 0 && (
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
                                ShipTo:
                            </Typography>
                            <FormGroup sx={{ pl: 10 }}>
                                {customerUserData.savedCustomerShipToes.map((shipTo) => (
                                    <FormControlLabel
                                        control={<Radio />}
                                        label={shipTo.text}
                                        value={shipTo.value}
                                        checked={shipTo.selected}
                                    />
                                ))}
                            </FormGroup>
                            <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
                                If there are no ship to accounts, user will receive all Order Received Notifications.
                            </Typography>
                        </Box>
                    )}

                    <Typography sx={{ fontWeight: 'bold', my:3 }}>
                        Contact your primary user, {customerUserData?.primaryAccountEmail} for additional access.
                    </Typography>                    

                    <Box sx={{display:'flex' ,justifyContent:'center'}}>
                        <Button onClick={handleClose} sx={{ mt: 3 }} variant="outlined">
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </>
    );
}

export default HomepageSecondaryUser;


