import React, { useEffect, useState } from "react";
import { AdvancedMarker, APIProvider, InfoWindow, Map } from '@vis.gl/react-google-maps';
import bluePinImg from '../../../img/pin-blue.png';
import grayPinImg from '../../../img/pin-gray.png';
import { ShipTo } from "../../models/warehouse/types";
import MapTypeEnum from "../../models/common/types";

export interface ShipToMapComponentProps {
  mapKey: string;
  shipTos: ShipTo[];
  longitudeCenterOffset: number;
  mapType: MapTypeEnum;
  disableControls: boolean;
}

export interface ShipToMarker {
  latitude: number | null;
  longitude: number | null;
  shipTo: string;
  location: string;
  isSelected?: boolean;
}

export interface ShipToLocation {
  location: string;
  shipTo: string;
}

const ShipToMapComponent: React.FC<ShipToMapComponentProps> = ({ mapKey, shipTos, longitudeCenterOffset,
  disableControls, mapType }) => {
  const [selectedShipToOnMap, setSelectedShipToOnMap] = useState<ShipToMarker | null>(null);
  const [selectedLatitude, setSelectedLatitude] = useState<number | null>(null);
  const [selectedLongitude, setSelectedLongitude] = useState<number | null>(null);
  const [markers, setMarkers] = useState<ShipToMarker[]>([]);

  useEffect(() => {
    if (mapType === MapTypeEnum.UseMyCurrentLocation) {
      navigator.geolocation.getCurrentPosition(function (res) {
        setSelectedLatitude(res.coords.latitude);
        setSelectedLongitude(res.coords.longitude);
      });
    }
    const selectedShipTo = shipTos.find(x => x.isSelected);
    const mappedLocations = shipTos.map(shipTo => ({
      location: shipTo.fullAddress,
      shipTo: shipTo.fullName
    }));
    const uniqueLocations = getUnique(mappedLocations);
    const timer = setInterval(() => {
      if (window.google && window.google.maps && window.google.maps.Geocoder) {
      const geocoder = new window.google.maps.Geocoder();
      const markers: ShipToMarker[] = [];
      uniqueLocations.forEach(loc => {
        geocoder.geocode({ address: loc.location }, (results, status) => {
          if (status === 'OK' && results !== null && results[0]) {
            const location = results[0]?.geometry.location;
            if (location) {
              const marker: ShipToMarker = {
                latitude: location.lat(),
                longitude: location.lng(),
                shipTo: loc.shipTo,
                location: loc.location,
                isSelected: selectedShipTo?.fullAddress === loc.location
              };
                markers.push(marker);
                setMarkers([...markers]);
              if (mapType !== MapTypeEnum.UseMyCurrentLocation && selectedShipTo?.fullAddress === loc.location) {
                setSelectedLatitude(location.lat());
                setSelectedLongitude(location.lng());
              }
            }
          } else {
            console.error(`Error finding location for address: ${loc.location}`);
          }
        });
      });
      clearInterval(timer);
    }
    }, 500);
    return () => clearInterval(timer);
  }, [mapType ,shipTos]);

    function getUnique(array: ShipToLocation[]): ShipToLocation[] {
      const uniqueArray: { location: string; shipTo: string }[] = [];
      const uniqueLocationArray: string[] = [];
  
      const locationArray = array.map(a => a.location);
  
      for (let i = 0; i < array.length; i++) {
          if (uniqueLocationArray.indexOf(locationArray[i]) === -1) {
              uniqueLocationArray.push(locationArray[i]);
              uniqueArray.push(array[i]);
          } else {
              const index = uniqueLocationArray.indexOf(locationArray[i]);
              const shipTo = uniqueArray[index].shipTo;
              uniqueArray[index] = { location: array[i].location, shipTo: `${shipTo}, ${array[i].shipTo}` };
          }
      }
  
      return uniqueArray;
    }

  return (
    <>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}>
        <div className="mapholderModal">
          <Map
            key={mapKey + selectedLatitude + selectedLongitude }
            mapId={mapKey}
            defaultZoom={10}
            defaultCenter={{ lat: selectedLatitude ?? 0, lng: (selectedLongitude ?? 0) + longitudeCenterOffset }}
            disableDefaultUI={disableControls}
            zoomControl={!disableControls}
            fullscreenControl={!disableControls}
            disableDoubleClickZoom={disableControls}
            gestureHandling={disableControls ? "none" : null}
            renderingType='UNINITIALIZED'
            style={{ width: '100%', minHeight: '80%' }}
          >
            {markers.map((marker) => (
              <AdvancedMarker
                position={{lat: marker.latitude ?? 0, lng: marker.longitude ?? 0}}
                onClick={() => {
                  if (!disableControls)
                    setSelectedShipToOnMap(marker);
                }}>
                <img
                  src={marker.isSelected ? bluePinImg : grayPinImg}
                  title={marker.shipTo}
                  style={{ width: "50px", height: "50px", cursor: disableControls ? "default" : "pointer" }}
                />
              </AdvancedMarker>
            ))}

            {selectedShipToOnMap && (
              <InfoWindow
                position={{
                  lat: selectedShipToOnMap.latitude ?? 0,
                  lng: selectedShipToOnMap.longitude ?? 0,
                }}
                onCloseClick={() => {
                  setSelectedShipToOnMap(null);
                }}
              >
                <div>
                  <h2>{selectedShipToOnMap.shipTo}</h2>
                  <p>{selectedShipToOnMap.location}</p>
                </div>
              </InfoWindow>
            )}
          </Map>
        </div>
      </APIProvider>
    </>
  );
};

export default ShipToMapComponent;
