import Grid from '@mui/material/Grid';
import React from 'react';
import { Link } from 'react-router-dom';
import useAppUrl from '../../hooks/useAppUrl';

export interface HomePageCategoriesProp {
    categoryName: string,
    categoryImage: string
}

const HomePageCategory: React.FC<HomePageCategoriesProp> = ({ categoryName, categoryImage }) => {
    let categoryTitle = "Show products in category " + categoryName;
    let categoryStyles = {
        backgroundImage: `url(${categoryImage})`
    };

    const { appCategoryUrl } = useAppUrl();

    let categoryUrl = categoryName === "Windows"
        ? appCategoryUrl("/gallery?searchWindows=true")
        : appCategoryUrl(`/gallery?categorylvl1=${encodeURIComponent(categoryName)}`);

    return (
        <Grid item xs={12} md={4} className='featured-categories-grid'>
            <div className='category' style={categoryStyles}>
                <div className='category-title'>
                <Link to={categoryUrl} title={categoryTitle}>
                    <span>{categoryName}</span>
                    <span className="view-all">view all</span>                       
                </Link>
                </div>
            </div>
        </Grid>
    );
};

export default HomePageCategory