import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useMediaQuery, useTheme } from "@mui/material";

const HeaderWarning: React.FC = () => {
  const { globalMessage: message } = useSelector(
    (state: RootState) => state.warehouseState.warehouseInfo
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  let headerStyle = {
    color: "#fff",
    textAlign: "center" as const,
    height: isMobile ? "max-content" : "35px",
    lineHeight: "30px",
    fontWeight: "bold",
    backgroundColor: "#fbb040",
  };

  return message == null ? (
    <></>
  ) : (
    <div
      className="infoGlobalHeader"
      style={headerStyle}
      dangerouslySetInnerHTML={{ __html: message ?? "" }}
    ></div>
  );
};

export default HeaderWarning;
