import { useEffect, useState } from "react";
import { useGetImpactProgramsInfoQuery } from "../store/services/dataApi";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const useImpactPrograms = () => {
    const warehouse = useSelector((state: RootState) => state.warehouseState.selectedWarehouse);
    const [builderIOData, setBuilderIOData] = useState<any>();
    const { data, isLoading } = useGetImpactProgramsInfoQuery(warehouse.warehouseCode);
    const result = data?.data;

    useEffect(() => {
        if (result === undefined) return;

        setBuilderIOData({
            leadsVisible: result!.proZoneVisible,
            impactRewardsVisible: result!.impactRewardsVisible,
            loyaltyLinkVisible: result!.savrVisible,
            marketingServicesVisible: result!.marketingServicesVisible,
            financingSolutionsVisible: result!.greenFinancingVisible
        });

    }, [result]);

    const stateChangedHandler = (state: any) => {
        if (state.leadsVisible === undefined)
            return;

        if (
            builderIOData?.leadsVisible !== state.leadsVisible ||
            builderIOData?.impactRewardsVisible !== state.impactRewardsVisible ||
            builderIOData?.loyaltyLinkVisible !== state.loyaltyLinkVisible ||
            builderIOData?.marketingServicesVisible !== state.marketingServicesVisible ||
            builderIOData?.financingSolutionsVisible !== state.financingSolutionsVisible
        ) {
            setBuilderIOData({
                leadsVisible: state.leadsVisible,
                impactRewardsVisible: state.impactRewardsVisible,
                loyaltyLinkVisible: state.loyaltyLinkVisible,
                marketingServicesVisible: state.marketingServicesVisible,
                financingSolutionsVisible: state.financingSolutionsVisible
            });
        }
    };

    return { builderIOData, isLoading, stateChangedHandler };
};


export default useImpactPrograms;