import React, { useEffect, useState } from "react";
import withAppProviders from "./withAppProviders";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useAuthentication from "./hooks/useAuthentication";
import PageLoading from "./components/shared/pageLoading";
import Footer from "./components/footer/footer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store/store";
import { fetchWarehouseDetails } from "./store/slices/warehouseSlice";
import ScrollTop from "./utils/scrollTop";
import FaviconSetter from "./utils/faviconSetter";
import HeaderWarning from "./components/header/headerWarning";
import { Box } from "@mui/material";
import WarehouseBanner from "./components/header/warehouseBanner";
import MainMenu from "./components/navbar/mainMenu";
import BuilderIOPage from "./builderio/components/builderIOPage";
import "./builderio/builder-registry";
import FinancingSolutions from "./pages/impact-programs/FinancingSolutions";
import ImpactPrograms from "./pages/impact-programs/ImpactPrograms";
import LansingMarketingServices from "./pages/impact-programs/LansingMarketingServices";
import LeadGeneration from "./pages/impact-programs/LeadGeneration";
import LoyaltyLink from "./pages/impact-programs/LoyaltyLink";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import ImpactRewards from "./pages/impact-programs/ImpactRewards";
import "../assets/css/fonts.css";
import TagManager from "react-gtm-module";
import ProductGallery from "./pages/productGallery/productGallery";
import Catalog from "./pages/catalog/catalog";
import Product from "./pages/product/product";
import Homepage from "./pages/home/homepage";
import "../assets/css/App.css";
import "../assets/css/AppExtensions.css";
import { Button, useMediaQuery, useTheme } from "@mui/material";

const App: React.FC = () => {
  
  window.addEventListener("scroll", () => {
    setTimeout(() => {
      if (window.scrollY === 0) document.body.classList.remove("notAtPageTop");
      else document.body.classList.add("notAtPageTop");
    }, 100)
  });

  // Floating mobile header
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [topMargin, setTopMargin] = useState(0);
  useEffect(() => {
    const element = document.querySelector(".fullHeaderBanner");
    if (element) {
      setTopMargin(element.clientHeight);
    }
  });

  // Google Tag Manager Initialization
  if (process.env.REACT_APP_GTM_KEY) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_KEY,
    };

    TagManager.initialize(tagManagerArgs);
  }

  const { isAuthenticated, isAuthenticating } = useAuthentication();
  const impersonatedCustomer = useSelector(
    (state: RootState) => state.userState.user.impersonatedCustomer
  );
  var isAdmin = useSelector((state: RootState) => state.userState.user.isAdmin);
  var isInternal = useSelector((state: RootState) => state.userState.user.isInternal);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!isAuthenticating) {
      dispatch(fetchWarehouseDetails(null));
    }
  }, [dispatch, isAuthenticating]);

  const { warehouseCode } = useSelector(
    (state: RootState) => state.warehouseState.selectedWarehouse
  );

  const { isWarehouseInfoLoaded } = useSelector(
    (state: RootState) => state.warehouseState
  );

  if (isAuthenticating || isWarehouseInfoLoaded === false) {
    return <PageLoading />;
  }

  let basename = process.env.REACT_APP_SUBDIRECTORY;
  if (isAuthenticated) {
    basename = process.env.REACT_APP_STOREFRONT_SUBDIRECTORY;
  }

  return (
    <BrowserRouter basename={"/" + basename}>
      <ScrollTop />
      <FaviconSetter />
      
      <Box sx={boxStyles} className="fullHeaderBanner">
          <HeaderWarning />
          {(isInternal || impersonatedCustomer) && (
          <div className="headerImpersonatingMessage">
            {isInternal && (
              <a
                href={process.env.REACT_APP_WEB_URL + "/Admin"}
                style={{ textDecoration: "none", color: "white" }}
              >
                Administration
              </a>
            )}
            {impersonatedCustomer && (
              <>
                <span
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginRight: "20px",
                    cursor: "default",
                  }}
                >
                  Impersonating {impersonatedCustomer}
                </span>
                <a
                  href={process.env.REACT_APP_WEB_URL + "/logout"}
                  title="Click here to Logout."
                  style={{ textDecoration: "none", color: "white" }}
                >
                  log out
                </a>
              </>
            )}
          </div>
        )}
        {warehouseCode != null && <WarehouseBanner />}
        <MainMenu />
      </Box>
      <div className="page-margin" style={isMobile ? {
        marginTop: topMargin + "px"
      } : {}}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/impact-programs/impact-programs-overview"
            element={<ImpactPrograms />}
          />
          <Route
            path="/impact-programs/financing-solutions"
            element={<FinancingSolutions />}
          />
          <Route
            path="/impact-programs/impact-rewards"
            element={<ImpactRewards />}
          />
          <Route
            path="/impact-programs/lansing-marketing-services"
            element={<LansingMarketingServices />}
          />
          <Route
            path="/impact-programs/lead-generation"
            element={<LeadGeneration />}
          />
          <Route path="/impact-programs/loyalty-link" element={<LoyaltyLink />} />
          <Route
            path="/gallery"
            element={<ProductGallery warehouseCode={warehouseCode} />}
          />
          <Route path="/catalog" element={<Catalog />} />
          <Route
            path="/product"
            element={<Product warehouseCode={warehouseCode} />}
          />
          <Route path="*" element={<BuilderIOPage />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

const boxStyles = {
  width: "100%",
  bgcolor: "white",
  "@media (max-width: 1199px)": {
    position: "fixed",
    top: 0,
    zIndex: 1000,
  },
};

const AppWithProviders = withAppProviders(App);

export default AppWithProviders;
