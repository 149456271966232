import React from 'react';
import BuilderIOPage from '../../builderio/components/builderIOPage';
import PageLoading from '../../components/shared/pageLoading';
import useImpactPrograms from '../../hooks/useImpactPrograms';

const FinancingSolutions: React.FC = () => {
    const { builderIOData, isLoading, stateChangedHandler } = useImpactPrograms();

    if (isLoading) {
        return <PageLoading />;
    }

    return (
        <BuilderIOPage
            data={builderIOData}
            onStateChange={stateChangedHandler}
            url="/impact-programs/financing-solutions" />
    );
};

export default FinancingSolutions;