import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import WarehouseDialog from "./warehouseDialog";
import ShipToDialog from "./shiptoDialog";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import pinHeaderImg from "../../../img/pin-header.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { fetchWarehouseDetails } from '../../store/slices/warehouseSlice';

const WarehouseBanner: React.FC = () => {
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const [shipToDialogOpen, setShipToDialogOpen] = useState<boolean>(false);

	const shipToDialogOpenFromStore = useSelector((state: RootState) => state.shiptToState.isDialogOpen);

	useEffect(() => {
		if (shipToDialogOpenFromStore) {
			setShipToDialogOpen(true);
		}
	}, [shipToDialogOpenFromStore]);

	const {
		openRequestedAt,
		warehouseInfo: {
			hoursFrom: warehouseHoursFrom,
			hoursTo: warehouseHoursTo,
			branchMessage: message
		},
		selectedWarehouse: {
			warehouseName
		}
	} = useSelector((state: RootState) => state.warehouseState);

	const dispatch = useDispatch<AppDispatch>();

	const defaultShipTo = useSelector((state: RootState) => state.userState.user.defaultShipTo) || "N/A";
	const isAuthenticated = useSelector((state: RootState) => state.userState.isAuthenticated);

	useEffect(() => {
		if (openRequestedAt != null)
			setDialogOpen(true);
	}, [openRequestedAt]);

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	const handleOpenDialog = () => {
		setDialogOpen(true);
	};

	const handleOpenShipToDialog = () => {
		setShipToDialogOpen(true);
	};

	const handleCloseShipToDialog = () => {
		setShipToDialogOpen(false);
	};

	const handleWarehouseChange = (warehouse: string) => {
		dispatch(fetchWarehouseDetails(warehouse));
	};

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<>
			<Grid className='branchBannerContainer'
				container
				sx={{
					px: { lg: 3, md: 3, xs: 1 },
					py: 1,
					justifyContent: { xl: 'space-around', xs: 'flex-start' }
				}}
				spacing={0}
				direction="row"
				alignItems="center">

				<Grid item md={isAuthenticated ? 10 : 12}>
					<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
						<img src={pinHeaderImg} alt="Branch Pin Thumb" height={18} width={18} />
						<div>
							<Typography
								className='branchBannerInfo'
								onClick={handleOpenDialog}
								style={{ cursor: "pointer" }}
							>
								Powered By Your {warehouseName} Branch &gt;&gt;
							</Typography>
							<div className='branchBannerInfoWorkTime'>{message} {warehouseHoursFrom} - {warehouseHoursTo}</div>
						</div>
					</Stack>
				</Grid>
				<Grid item md={2} textAlign={'end'}>
					{!isMobile && isAuthenticated && defaultShipTo && defaultShipTo !== "N/A" &&
						<>
							<Typography className='branchBannerInfo'>
								Ship To:
							</Typography>
							<div style={{ cursor: "pointer" }} onClick={handleOpenShipToDialog} className='branchBannerInfoWorkTime'>{defaultShipTo}</div>
						</>
					}
				</Grid>
			</Grid>
			<WarehouseDialog
				open={dialogOpen}
				onClose={handleCloseDialog}
				onWarehouseSelected={handleWarehouseChange}
			/>
			{isAuthenticated &&
				<ShipToDialog
					open={shipToDialogOpen}
					onClose={handleCloseShipToDialog}
				/>
			}
		</>
	);
};

export default WarehouseBanner;