import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    TextField,
} from "@mui/material";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import dataService from "../../services/dataService";
import { ShipTo } from "../../models/warehouse/types";
import MapTypeEnum from "../../models/common/types";
import ShipToMapComponent from "./shipToMapComponent";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { closeShipToDialog } from "../../store/slices/shiptoSlice";

interface ShipToDialogProps {
    open: boolean;
    onClose: () => void;
}

const ShipToDialog: React.FC<ShipToDialogProps> = ({ open, onClose }) => {

    const [shipTos, setShipTos] = useState<ShipTo[]>([]);
    const [shipToMapModel, setShipToMapModel] = useState<ShipTo[]>([]);
    const [shipToRows, setShipToRows] = useState<GridRowsProp>([]);
    const [selectedShipToRow, setSelectedShipToRow] = useState<GridRowsProp>([]);
    const [searchTmpQuery, setSearchTmpQuery] = useState<string>('');
    const [mapType, setMapType] = useState<MapTypeEnum>(MapTypeEnum.SearchText);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [newShipToId, setNewShipToId] = useState<number | null>(null);
    const [searchTmpQueryChanged, setSearchTmpQueryChanged] = useState<boolean>(true);

    const dispatch = useDispatch();

    useEffect(() => {
        const getShipTos = async () => {
            await fetchShipTos()
        };
        getShipTos();
    }, [searchTmpQueryChanged]); 
    
    const fetchShipTos = async () => {
        const response = await dataService.getShipTos(searchTmpQuery);
            if (response.success === true) {    
                var data = response.data;
                setShipTos(data); 
                const shipTosMap = data.filter(shipTo => shipTo.isSelected || data.indexOf(shipTo) < 5);
                setShipToMapModel(shipTosMap);    
                const shipToRows = data
                    .map((x: ShipTo) => ({ 
                        id: x.id,
                        name: x.fullName,
                        address: x.fullAddress,
                        isSelected: x.isSelected,
                        options: '' // Options column will render the button
                    }));
                setShipToRows(shipToRows.filter((shipTo) => !shipTo.isSelected));  
                setSelectedShipToRow(shipToRows.filter((shipTo) => shipTo.isSelected));                           
             }
             else {
                console.error('Error fetching ship tos');
            }
    }

    const handleShipToSelect = async (newShipToId: number) => {
        const response = await dataService.validateShipToChange(newShipToId);
        if (response.data.needsUserConfirmation !== true) {    
            await dataService.setSelectedShipTo(newShipToId.toString());
            handleDialogClose();
            window.location.reload();
        }
        else {
            setConfirmationMessage(response.data.message ?? '');
            setNewShipToId(newShipToId);
            setConfirmationOpen(true);
        }          
        handleDialogClose();
    };  

    const handleConfirmationClose = async (confirmed: boolean, newShipToId: number) => {
        setConfirmationOpen(false);
        if (confirmed) {
            const response = await dataService.setSelectedShipTo(newShipToId.toString());
            if (response.success === true) {   
                window.location.reload();
            }
            else {
                console.error('Error setting ship to');
            }
        }
    };

    const handleDialogClose = () => {
        onClose();
        handleClear();
        dispatch(closeShipToDialog());
    }; 

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setMapType(MapTypeEnum.SearchText);
        }
    };

    const handleClear = () => {
        if (searchTmpQuery !== '') {
            setSearchTmpQuery('');
        }
        setSearchTmpQueryChanged(!searchTmpQueryChanged);
        setMapType(MapTypeEnum.SearchText);
    };

    const handleSearch = () => {
        setMapType(MapTypeEnum.SearchText);
        setSearchTmpQueryChanged(!searchTmpQueryChanged);
    };

    const onUseCurrentLocation = () => {
        setMapType(MapTypeEnum.UseMyCurrentLocation);
    };
    
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'address', headerName: 'Address',flex: 2 },
        {
            field: 'options',
            headerName: 'Options',
            flex: 1,
            headerAlign:'center',
            align:'center',
            renderCell: (params) => {
                return params.row.isSelected ?
                    (
                        <Button variant="outlined" color="primary" className='whiteButton' disabled={true} >
                            Selected
                        </Button>
                    )
                    : (
                        <Button variant="outlined" color="primary" className='blueButtonOutline' onClick={() => handleShipToSelect(params.row.id)}>
                            Select
                        </Button>
                    )
            }
        },
    ];  
    

    return (
        <><Dialog open={open} onClose={handleDialogClose} maxWidth="xl" fullWidth className='warehouseModal'>
            <DialogContent sx={{ position: "relative" }}>
                <Button 
                    sx={{ position: "absolute", top: 10, right: 10, minWidth: "auto" }}
                    onClick={handleDialogClose}
                >
                    <CloseIcon />
                </Button>                
                <Grid container spacing={2}>
                    <Grid item md={12} sx={{ textAlign: "center" }}>
                        <h2>Your Ship To's</h2>
                    </Grid>
                    <Grid item md={7}>
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ fontWeight: 600 }}>Current Ship To</h1>
                                <DataGrid
                                    rows={selectedShipToRow}
                                    columns={columns}
                                    autoHeight
                                    hideFooter
                                    hideFooterPagination
                                    getRowHeight={() => 'auto'} 
                                    disableColumnSorting={true} 
                                    disableColumnFilter={true} 
                                    disableColumnMenu={true}/>
                            </Grid>
                            <Grid item xs={12}>
                                <h1 style={{ fontWeight: 600 }}>Your Other Ship To's</h1>
                                <DataGrid
                                    rows={shipToRows}
                                    columns={columns}
                                    getRowHeight={() => 'auto'}
                                    disableColumnSorting={true} 
                                    disableColumnFilter={true} 
                                    disableColumnMenu={true}
                                    autoHeight
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10, 20]}
                                    onPaginationModelChange={(params) => {
                                        const newPageSize = params.pageSize;
                                        const newPage = params.page;
                                        const paginatedRows = shipToRows.slice(newPage * newPageSize, (newPage + 1) * newPageSize);
                                        const paginatedShipTos: ShipTo[] = paginatedRows.map(row => shipTos.find(shipTo => shipTo.id === row.id)!);
                                        const selectedShipTo = shipTos.filter(shipTo => shipTo.isSelected);
                                        const fullShipTos = paginatedShipTos.concat(selectedShipTo);
                                        setShipToMapModel(fullShipTos);
                                    }}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item md={5} xs={12} sx={{ mb: { xs: 0, sm: 10 }, }}>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                variant="outlined"
                                type="text"
                                value={searchTmpQuery}
                                className="mapSearchInput"
                                onChange={(e) => setSearchTmpQuery(e.target.value)}
                                onKeyDown={handleSearchKeyDown}
                                placeholder="Search Ship To's"
                                style={{ marginRight: "10px", marginBottom: "10px" }}
                                autoComplete="off" />
                            <Button className="blueButtonOutline mapSearchButton" onClick={handleSearch} sx={{ mr: 1, px: 5 }}>Search</Button>
                            <Button className="blueButtonOutline mapSearchButton" sx={{px:5}} onClick={handleClear}>Clear</Button>
                        </Grid>
                        <Button size="small" sx={{ my: 1 }} style={{ fontSize: "75%" }} variant="text" className="mapUseCurrentLocation" onClick={onUseCurrentLocation}><MyLocationIcon fontSize="small" sx={{ mr: 1 }} /> Use My Current Location</Button>
                        <Grid item xs={12}>
                            <div className="shipToDialogMapContainer">
                                {shipToMapModel && (
                                    <ShipToMapComponent
                                        mapKey="SHIPTO_MAP_DIALOG"
                                        longitudeCenterOffset={0}
                                        disableControls={false}
                                        mapType={mapType}
                                        shipTos={shipToMapModel} />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)} maxWidth="xs" fullWidth>
            <DialogContent>
                <Typography>{confirmationMessage}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" className='blueButtonOutline' onClick={() => newShipToId !== null && handleConfirmationClose(true, newShipToId)} autoFocus>
                    CONTINUE
                </Button>
                <Button variant="outlined" color="primary" className='blueButtonOutline' onClick={() => newShipToId !== null && handleConfirmationClose(false, newShipToId)}>
                    CANCEL
                </Button>
            </DialogActions>
        </Dialog></>
    );
};

export default ShipToDialog;