import { useEffect } from "react";
import { getUserProfileDataAsync } from "../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";

function getEncodedQueryString() {
  const params = new URLSearchParams(window.location.search);
  const encodedParams = new URLSearchParams();

  params.forEach((value, key) => {
    encodedParams.set(key, encodeURIComponent(value));
  });

  return (encodedParams.toString() === "" ? "" : "?") + encodedParams.toString();
}

const useAuthentication = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getUserProfileDataAsync());
  }, [dispatch]);

  const userData = useSelector((state: RootState) => state.userState);
  const user = userData.user;

  //until we have one place for both visitor and authenticated user we should switch between those two urls
  useEffect(() => {
    if (!userData.isUserLoaded) return;

    const customerPath = "";//process.env.REACT_APP_STOREFRONT_SUBDIRECTORY!;
    const visitorPath = process.env.REACT_APP_SUBDIRECTORY!;
    const pathname = document.location.pathname;

    const isVisitor = user.id === null || user.id === 0;
    const isOnVisitorPath = pathname.includes(visitorPath);

    if (isVisitor && !isOnVisitorPath) {
      const visitorSubdirectory = `/${process.env.REACT_APP_SUBDIRECTORY}`;
      const customerSubdirectory = `/${process.env.REACT_APP_STOREFRONT_SUBDIRECTORY}`;

      // Preserve query parameters for homepage if /lbpcustomer page is accessed
      let queryParameters = "";

      // Preserve pathname and query parameters if /lbpcustomer page is accessed, otherwise set to visitor homepage
      let pathName = window.location.pathname;
      if (pathName.startsWith(customerSubdirectory)) {
        pathName = pathName.replace(customerSubdirectory, visitorSubdirectory);
        queryParameters = `${window.location.search}`;
      }
      else
        pathName = visitorSubdirectory;

      window.history.pushState({}, "", `${pathName}${queryParameters}`);
      window.location.reload();
    } else if (!isVisitor && isOnVisitorPath) {
      // Preserve deep link when redirecting to MVC
      const relativeBaseUrl = window.location.pathname.slice("/visitor".length);
      const returnUrl = "?returnUrl=/deeplink" + encodeURIComponent(`${relativeBaseUrl}${getEncodedQueryString()}${window.location.hash}`);
      const redirectUrl = customerPath === "" ? `/quicklogin${returnUrl}` : `/${customerPath}/quicklogin${returnUrl}`;

      window.history.replaceState({}, "", redirectUrl);
      window.location.reload();
    }
  }, [user.id, userData.isUserLoaded]);

  return {
    isAuthenticated: user.id !== null && user.id !== 0,
    isAuthenticating: !userData.isUserLoaded,
    user: user,
  };
};

export default useAuthentication;
