import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, HierarchicalMenuProps, ConfigureProps, UseHierarchicalMenuProps } from 'react-instantsearch';
import CustomHierarchicalMenu from "./mainMenuCategoriesHierarchy";
import React from 'react'
import { useState, useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { useGetSortedCategoriesQuery } from '../../store/services/dataApi';
import useAppUrl from '../../hooks/useAppUrl';
import dataService from '../../services/dataService';

export interface CustomHierarchicalMenuProps extends UseHierarchicalMenuProps {
    onSubcategoryOpened: (opened: boolean) => void;
    navForceRedirect: (navigate: NavigateFunction, lvl1: string | undefined, lvl2: string | undefined, lvl3: string | undefined, isSmartSkip: boolean) => void;
}

export interface MainMenuCategoriesProps {
    warehouseCode: string | null;
    onSubcategoryOpened: (opened: boolean) => void;
}

const MainMenuCategories: React.FC<MainMenuCategoriesProps> = ({ warehouseCode, onSubcategoryOpened }) => {

    const { appUrlNavigate } = useAppUrl();

    const searchClient = algoliasearch(
        process.env.REACT_APP_ALGOLIA_ID ?? "",
        process.env.REACT_APP_ALGOLIA_KEY ?? ""
    );

    let [redirectInProgress, setRedirectInProgress] = useState<boolean>(false);
    let [sortedCategories, setSortedCategories] = useState<string[]>([]);

    // let selectedNavItems: any = null;
    let navLvl1Data: any = null;
    let navLvl2Parent: string | null = null;
    let navLvl2Data: any = null;

    useEffect(() => {
        const fetchSortedCategories = async () => {
            const result = await dataService.getSortedCategories();
            setSortedCategories(result.data);
        };

        fetchSortedCategories();
    }, []);

    const categoriesSortBy: HierarchicalMenuProps['sortBy'] = (a, b) => {
        if (a.path.includes(">") || b.path.includes(">")) {
            if (a.path.endsWith("Other") && !b.path.endsWith("Other")) {
                return 1;
            }
            else if (a.path.endsWith("Other Extrusions") && !b.path.endsWith("Other Extrusions")) {
                return 1;
            }
            else if (!a.path.endsWith("Other") && b.path.endsWith("Other")) {
                return -1;
            }
            else if (!a.path.endsWith("Other Extrusions") && b.path.endsWith("Other Extrusions")) {
                return -1;
            }
            else
                return a.name.localeCompare(b.name);
        }
        else {
            if (a.name === a.path)
                return sortedCategories.indexOf(a.name) - sortedCategories.indexOf(b.name);
            else
                return a.name.localeCompare(b.name);
        }
    };

    const transformItems: HierarchicalMenuProps['transformItems'] = (items, { results }) => {
        let dynamicResults = (results as any);

        // Store the Lvl1 data
        if (navLvl1Data === null || navLvl1Data.length === 0)
            navLvl1Data = dynamicResults.hits;

        // Store Lvl2 data
        for (let hitIndex in dynamicResults.hierarchicalFacets[0].data) {
            let tmpLvl1Element = dynamicResults.hierarchicalFacets[0].data[hitIndex];

            if (tmpLvl1Element.isRefined) {
                let isLvl2Refined = false;
                for (let lvl2Index in tmpLvl1Element.data) {
                    if (tmpLvl1Element.data[lvl2Index].isRefined)
                        isLvl2Refined = true;
                }

                if (!isLvl2Refined) {
                    if (navLvl2Parent !== tmpLvl1Element.name) {
                        navLvl2Parent = tmpLvl1Element.name;
                    }
                    navLvl2Data = dynamicResults.hits;
                }
            }
        }

        return items.map(item => {
            let hierarchyElements = item.value.split(" > ");
            let haschildren = false;

            // Prepare the data
            for (let hitIndex in navLvl1Data) {
                if (hierarchyElements[0] === navLvl1Data[hitIndex].lvl1 && navLvl1Data[hitIndex].lvl2.length > 0)
                    haschildren = true;

                if (item.value === navLvl2Parent && item.data) {
                    for (let dataIndex in item.data) {
                        let itemDataParent = item.label;
                        let itemData = itemDataParent + ' > ' + item.data[dataIndex].label;

                        for (let lvl2Index in navLvl2Data) {
                            if (itemDataParent === navLvl2Data[lvl2Index].lvl1 && navLvl2Data[lvl2Index].lvl2.includes(itemData)) {
                                (item.data[dataIndex] as any).issmartskip = navLvl2Data[lvl2Index].issmartskip;
                            }

                            if (itemDataParent === navLvl2Data[lvl2Index].lvl1 && navLvl2Data[lvl2Index].lvl2.includes(itemData)
                                && navLvl2Data[lvl2Index].lvl3.length > 0) {
                                (item.data[dataIndex] as any).haschildren = true;
                            }
                        }
                    }
                }
            }

            return ({
                ...item,
                haschildren: haschildren
            });
        });
    };


    let warehouseFilter = warehouseCode === null ? "warehouse:*" : ("warehouse:" + warehouseCode);

    const NavForceRedirect = (navigate: NavigateFunction, lvl1: string | undefined, lvl2: string | undefined, lvl3: string | undefined, isSmartSkip: boolean) => {
        setRedirectInProgress(true);

        if (lvl1 === "Renaissance Patio Products") {
            appUrlNavigate(`/gallery?searchRenaissance=true`);
        }
        else if (lvl1 === "Windows") {
            appUrlNavigate(`/gallery?searchWindows=true`);
        }
        else if (lvl1 === "My Most Purchased") {
            window.location.href = process.env.REACT_APP_WEB_URL + "/ProductListItem/FrequentlyPurchasedTemplate";
        }
        else if (lvl2 === undefined && lvl3 === undefined) {
            appUrlNavigate(`/gallery?categorylvl1=${encodeURIComponent(lvl1 ?? "")}`);
        }
        else if (lvl3 === undefined && !isSmartSkip) {
            appUrlNavigate(`/gallery?categorylvl1=${encodeURIComponent(lvl1 ?? "")}&jobType=${encodeURIComponent(lvl2 ?? "")}`);
        }
        else if (lvl3 === undefined && isSmartSkip) {
            appUrlNavigate(`/gallery?categorylvl1=${encodeURIComponent(lvl1 ?? "")}&productType=${encodeURIComponent(lvl2 ?? "")}`);
        }
        else {
            appUrlNavigate(`/gallery?categorylvl1=${encodeURIComponent(lvl1 ?? "")}&jobType=${encodeURIComponent(lvl2 ?? "")}&productType=${encodeURIComponent(lvl3 ?? "")}`);
        }
    }

    const configureProps = {
        filters: warehouseFilter,
        analytics: true,
        hitsPerPage: 1000
    } as ConfigureProps;

    const hierarchicalMenuProps = {
        attributes: [
            'lvl1',
            'lvl2',
            'lvl3',
        ],
        sortBy: categoriesSortBy,
        limit: 1000,
        showMoreLimit: Number.MAX_SAFE_INTEGER,
        transformItems: transformItems,
        onSubcategoryOpened: onSubcategoryOpened,
        navForceRedirect: NavForceRedirect
    } as CustomHierarchicalMenuProps;

    return sortedCategories.length > 0 && !redirectInProgress ?
        (
            <InstantSearch searchClient={searchClient} indexName={process.env.REACT_APP_ALGOLIA_CATEGORY_INDEX}>
                <Configure {...configureProps}>
                </Configure>
                <CustomHierarchicalMenu {...hierarchicalMenuProps}>
                </CustomHierarchicalMenu>
            </InstantSearch>
        ) : <></>;
}

export default MainMenuCategories;