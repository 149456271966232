
export const Permissions = {
  EnableBilling: 'EnableBilling',
  EnableOrdering: 'EnableOrdering',
  SAVRCustomerProgram: 'SAVRCustomerProgram',
  TripsCustomerProgram: 'TripsCustomerProgram',
  EnableOrderHistory: 'EnableOrderHistory',
  EnableProductBrowsing: 'EnableProductBrowsing',
  ProZoneCustomerProgram: 'ProZoneCustomerProgram',
  JobsAndMaterialList: 'JobsAndMaterialList',
  ProBucksCustomerProgram: 'ProBucksCustomerProgram',
  LansingNEView: 'LansingNEView',
  LegacyLansingView: 'LegacyLansingView',
  SelectBranch: 'SelectBranch',
  BillTrust: 'BillTrust',
  CheckNearbyStore: 'CheckNearbyStore',
  ManagePublicJobLists: 'ManagePublicJobLists',
  PublicStoreQuotes: 'PublicStoreQuotes',
  PublicOrderHistoryReorder: 'PublicOrderHistoryReorder',
  InternalUsersSelectBranch: 'InternalUsersSelectBranch',
  ManageUsers: 'ManageUsers',
  ExclusiveCustomer: 'ExclusiveCustomer',
  EnableShoppingCart: 'EnableShoppingCart'
}

export const MenuItems = {
  CustomerPrograms: 'IMPACT Programs',
  LeadGeneration: 'Lead Generation',
  ImpactRewards: 'Impact Rewards',
  LoyaltyLink: 'Loyalty Link',
  MarketingServices: 'Marketing Services',
  FinancingSolutions: 'Financing Solutions',
  TemplatesEstimates: 'Templates & Estimates',
  Templates: 'Templates',
  HomeownerEstimates: 'Homeowner Estimates',
  MyOrdersQuotes: 'My Orders & Quotes',
  Help: 'Help',
  ContactUs: 'Contact us',
  FAQ: 'FAQ',
  Orders: 'Orders',
  OpenQuotes: 'Open Quotes',
  MyMostPurchased: 'My Most Purchased',
  HoverMaterialListQuote: 'Hover Material List Quote'
}

export const BuilderIOSections = {
  FigmaSection: 'figma-section',
  FeatureZoneSection: 'feature-zone-carousel',
  TagFilterName: "data.tag",
  FeaturedCategoriesFilterValue: "featuredcategories2",
  FeatureZoneFilterValue: "featurezone"
}