import { Coordinates, ShipTo, ShipToChange, Warehouse } from '../models/warehouse/types';
import axiosService from './axiosService';
import { URLS } from './constants';
import { BaseResponse } from '../models/common/types';
import VersionURL from '../utils/versionUrl';
import { MiniShoppingCartModel, ProductDetails, UpdateMiniCartDto, VariantDetails } from '../models/product/types';
import { CustomerUser, FeaturedCategory } from '../models/customer/types';

class DataService {
    async getAllBranchesOrderedByLocation(coordinates: Coordinates): Promise<BaseResponse<Warehouse[]>> {
        const result = await axiosService.get<BaseResponse<Warehouse[]>>(
            VersionURL(URLS.GET_ALL_BRANCHES_ORDERED_BY_LOCATION_URL),
            {
                params: {
                    latitude: coordinates.latitude,
                    longitude: coordinates.longitude,
                },
            }
        );

        return result.data;;
    }

    async getAllBranches(warehouseCode: string, searchTerm: string | null): Promise<BaseResponse<Warehouse[]>> {
        const result = await axiosService.get<BaseResponse<Warehouse[]>>(
            VersionURL(URLS.GET_ALL_BRANCHES),
            {
                params: {
                    warehouseCode: warehouseCode,
                    searchTerm: searchTerm
                },
            }
        );

        return result.data;
    }

    async setSelectedWarehouse(warehouseCode: string): Promise<BaseResponse<boolean>> {
        const result = await axiosService.post<BaseResponse<boolean>>(
            VersionURL(URLS.SET_SELECTED_WAREHOUSE),
            null,
            {
                params: {
                    warehouseCode: warehouseCode
                }
            }
        );

        return result.data;
    }

    async getSortedCategories(): Promise<BaseResponse<string[]>> {
        const result = await axiosService.get<BaseResponse<string[]>>(
            VersionURL(URLS.GET_SORTED_CATEGORIES)
        );

        return result.data;
    }

    async getProductDetails(id: string | null, warehouseCode: string | null): Promise<BaseResponse<ProductDetails>> {
        const result = await axiosService.get<BaseResponse<ProductDetails>>(
            VersionURL(URLS.GET_PRODUCT_DETAILS),
            {
                params: {
                    id: id,
                    warehouseCode: warehouseCode
                }
            }
        );

        return result.data;
    }

    async getVariantDetails(id: number, warehouseCode: string): Promise<BaseResponse<VariantDetails>> {
        const result = await axiosService.get<BaseResponse<VariantDetails>>(
            VersionURL(URLS.GET_VARIANT_DETAILS),
            {
                params: {
                    id: id,
                    warehouseCode: warehouseCode
                }
            }
        );

        return result.data;
    }

    async getHexToColorMapping(): Promise<BaseResponse<any>> {
        const result = await axiosService.get<BaseResponse<any>>(
            VersionURL(URLS.GET_HEX_TO_COLOR_MAPPING)
        );

        return result.data;
    }

    async getFeaturedCategories(warehouseCode: string | null): Promise<BaseResponse<string[]>> {
        const result = await axiosService.get<BaseResponse<string[]>>(
            VersionURL(URLS.GET_FEATURED_CATEGORIES),
            {
                params: {
                    warehouseCode: warehouseCode
                }
            }
        );

        return result.data;
    }

    async getShipTos(searchTerm: string | null): Promise<BaseResponse<ShipTo[]>> {
        const result = await axiosService.get<BaseResponse<ShipTo[]>>(
            VersionURL(URLS.GET_SHIP_TOS),
            {
                params: {
                    searchTerm: searchTerm
                },
            }
        );

        return result.data;
    }

    async validateShipToChange(newShipToId: number): Promise<BaseResponse<ShipToChange>> {
        const result = await axiosService.get<BaseResponse<ShipToChange>>(
            VersionURL(URLS.VALIDATE_SHIP_TO_CHANGE),
            {
                params: {
                    newShipToId: newShipToId
                }
            }
        );

        return result.data;
    }

    async setSelectedShipTo(newShipToId: string | null): Promise<BaseResponse<boolean>> {
        const result = await axiosService.post<BaseResponse<boolean>>(
            VersionURL(URLS.SET_SELECTED_SHIP_TO),
            null,
            {
                params: {
                    newShipToId: newShipToId
                }
            }
        );

        return result.data;
    }

    async getSecondaryUserInfo(): Promise<BaseResponse<CustomerUser>> {
        const result = await axiosService.get<BaseResponse<CustomerUser>>(
            VersionURL(URLS.GET_SECONDARY_USER_INFO),        
        );

        return result.data;
    }

    async getMiniShoppingCart(): Promise<BaseResponse<MiniShoppingCartModel>> {
        const result = await axiosService.get<BaseResponse<MiniShoppingCartModel>>(
            VersionURL(URLS.GET_MINI_SHOPPING_CART)
        );

        return result.data;
    }

    async updateMiniCartItemQty(shoppingCartItemId: number, quantity: number): Promise<BaseResponse<MiniShoppingCartModel>> {
        const result = await axiosService.post<BaseResponse<MiniShoppingCartModel>>(
            VersionURL(URLS.UPDATE_MINI_CART_ITEM_QUANTITY),
            null,
            {
                params: {
                    shoppingCartItemId: shoppingCartItemId,
                    quantity: quantity
                }
            }
        );

        return result.data;
    }

    async deleteMiniCartItem(shoppingCartItemId: number,): Promise<BaseResponse<MiniShoppingCartModel>> {
        const result = await axiosService.post<BaseResponse<MiniShoppingCartModel>>(
            VersionURL(URLS.DELETE_MINI_CART_ITEM),
            null,
            {
                params: {
                    shoppingCartItemId: shoppingCartItemId
                }
            }
        );

        return result.data;
    }    

    async getFeaturedCategoriesVisibilities(warehouseCode: string, isAuthenticated: boolean): Promise<BaseResponse<{ [key: string]: FeaturedCategory }>> {
        const result = await axiosService.get<BaseResponse<{ [key: string]: FeaturedCategory }>>(
            VersionURL(URLS.GET_FEATURED_CATEGORIES_VISIBILITIES)
        );

        return result.data;
    }
}

const dataService = new DataService();
export default dataService;
