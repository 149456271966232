import { createSlice } from '@reduxjs/toolkit';

interface ShiptoState {
    isDialogOpen: boolean;
}

const initialState: ShiptoState = {
    isDialogOpen: false,
};

const shiptoSlice = createSlice({
    name: 'shipto',
    initialState,
    reducers: {
        openShipToDialog(state) {
            state.isDialogOpen = true;
        },
        closeShipToDialog(state) {
            state.isDialogOpen = false;
        },
    },
});

export const { openShipToDialog, closeShipToDialog } = shiptoSlice.actions;

export default shiptoSlice.reducer;