import React from 'react';
import BuilderIOPage from '../../builderio/components/builderIOPage';
import useImpactPrograms from '../../hooks/useImpactPrograms';
import PageLoading from '../../components/shared/pageLoading';

const LeadGeneration: React.FC = () => {
    const { builderIOData, isLoading, stateChangedHandler } = useImpactPrograms();

    if (isLoading) {
        return <PageLoading />;
    }

    return (
        <BuilderIOPage
            data={builderIOData}
            onStateChange={stateChangedHandler}
            url="/impact-programs/lead-generation" />
    );
};

export default LeadGeneration;