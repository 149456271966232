import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Grid from '@mui/material/Grid';
import featureZoneAnimation from '../../../img/featureZoneAnimation.gif';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Warehouse } from "../../models/warehouse/types";
import { setOpenRequestedAt } from "../../store/slices/warehouseSlice";
import StaticMapComponent from "../../components/shared/staticMapComponent";
import { Height } from "@mui/icons-material";
import BuilderIOSection from "../../builderio/components/builderIOSection";
import { BuilderIOSections } from "../../models/constants";

const HomepageFeatureZone: React.FC = () => {
    const {
        hoursFrom: warehouseHoursFrom,
        hoursTo: warehouseHoursTo,
        branchMessage: branchMsg,
        globalMessage: message,
        warehouses: allWarehouses
    } = useSelector((state: RootState) => state.warehouseState.warehouseInfo);

    const {
        warehouseCode,
        warehouseName,
        warehouseAddress,
        warehouseCity,
        warehouseState,
        zipCode,
    } = useSelector((state: RootState) => state.warehouseState.selectedWarehouse);


    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    let branchClosedMessage: string = "Branch Currently Closed.";
    var branchOpenMessage: string = "OPEN NOW!";
    let messageArray: string[] = [];
    if (branchMsg != null && branchMsg.includes(branchClosedMessage))
        messageArray = [branchClosedMessage, branchMsg.slice(branchClosedMessage.length)];
    else if (branchMsg !== null && branchMsg.includes(branchOpenMessage))
        messageArray = [branchOpenMessage, branchMsg.slice(branchOpenMessage.length)];
    else if (message !== null)
        messageArray = [message];

    let filteredWarehouses: Warehouse[] = allWarehouses.filter(x => x.warehouseCode === warehouseCode);

    const onUseCurrentLocation = () => {
        dispatch(setOpenRequestedAt(new Date()));
    };
    const isAuthenticated = useSelector((state: RootState) => state.userState.isAuthenticated);
    const hasMostPurchased = useSelector((state: RootState) => state.userState.user.hasMostPurchased);
    const hasDashboard = useSelector((state: RootState) => state.userState.user.hasDashboard);

    return (
        <div className="homepageFeatureZoneContainer">
            <Grid container direction="row" spacing={2} sx={{ justifyContent: "center", alignItems: "stretch", p: 1 }}>
                <Grid item xs={12} lg={4} className="grid-1">
                    <div className="mapHolder">
                        <div className="branch">
                            <div className="branchText">
                                <h1 className="m-0">Powered By Your Local <br /><span className="branchTextNormal">{warehouseName}</span><br /> Branch</h1>
                                <div style={{ lineHeight: "26px" }}>
                                    {warehouseAddress}<br />
                                    {warehouseCity}, {warehouseState}, {zipCode}<br /><br />
                                    <p>
                                        {messageArray.map((x, index) =>
                                            <span key={x}>
                                                <span dangerouslySetInnerHTML={{ __html: x ?? "" }}></span>
                                                {index !== messageArray.length - 1 && <br />}
                                            </span>
                                        )}
                                        <br />
                                        <b>{warehouseHoursFrom} - {warehouseHoursTo}</b>
                                    </p>
                                    <Button sx={{ color: "white", p: 0, mt: 2, textAlign: 'left', textTransform: 'capitalize' }} onClick={onUseCurrentLocation}><MyLocationIcon fontSize="small" sx={{ mr: 1 }} /> Use My Current Location</Button>
                                </div>
                            </div>
                        </div>
                        {filteredWarehouses == null
                            ? <></>
                            : <StaticMapComponent
                                selectedWarehouseCode={warehouseCode}
                                warehouses={filteredWarehouses}
                                longitudeCenterOffset={isMobile ? -0.26 : -0.2} />
                        }
                    </div>

                </Grid>
                <Grid item xs={12} lg={6} className="grid-2">
                {/* <Carousel
                        autoPlay={true}
                        interval={5000}
                        className="carouselContainer"
                        animation={"slide"}
                        duration={1000}
                        navButtonsAlwaysInvisible={true}
                        swipe={true}>
                        <div className="slide">
                            <h1>Supporting Your Business,<br />One Job at a Time</h1>
                            <h2>Seamlessly price and place orders, 24/7, with the dedicated support of your local Lansing team.</h2>
                            <Link className="whiteButton mt-1" to="/gallery?query=">Shop Now</Link>
                        </div>
                        <div className="slideBuilderWidget">
                            <BuilderIOSection section={BuilderIOSections.FeatureZoneSection} /> 
                        </div>
                    </Carousel> */}
                    <BuilderIOSection section={BuilderIOSections.FeatureZoneSection} /> 
                </Grid>

                {isAuthenticated ?
                <Grid  item xs={12} lg={2} className="grid-3 mb-5percLast"
                sx={{ display: { xs: 'flex', md: 'block' }, flexDirection: 'rows', alignItems: 'top', gap:'10px' }}
              >
                    {hasDashboard ?
                        <div className="carouselContainerSmallLogged mb-5perc mobHeightBox" style={{height:hasMostPurchased ? "42%" : "93.5%", backgroundColor:"#40C1AC"}}>
                            <Typography variant="h5" className="text-white"><b>My Dashboard</b></Typography>
                            <Typography className="text-white"> Schedule or review upcoming and recent deliveries. </Typography>
                            <Link className="view-now-btn w" to={process.env.REACT_APP_WEB_URL + "/Customer/DQDashboard"}>View Now</Link>
                        </div> : ""}
                    {hasMostPurchased ?
                    <div className="carouselContainerSmallLogged mobHeightBox" style={{height: hasDashboard ? "42%" : "93.5%", backgroundColor:"#0057B8"}}>
                        <Typography variant="h5"className="text-white"><b>My Most Purchased</b></Typography>
                        <Typography className="text-white">Quickly check pricing or re-order your preferred products. </Typography>
                        <Link className="view-now-btn" to={process.env.REACT_APP_WEB_URL + "/ProductListItem/FrequentlyPurchasedTemplate"}>View Now</Link>
                    </div> : ""}
                </Grid>
                : 
                <Grid item xs={12} lg={2} className="grid-3">
                    <div className="carouselContainerSmall">
                        <img className="animation" src={featureZoneAnimation} alt="Homepage Feature Zone Animation" width="auto" height="auto" />
                        <div className="linkContainer">
                            <Link className="whiteButton my-1" to={process.env.REACT_APP_WEB_URL + "/quicklogin"}>Log In</Link>
                            <Typography>Not Registered?<br /><Link style={{ color: "#353535" }} to={process.env.REACT_APP_WEB_URL + "/register"}>Create Account</Link></Typography>
                        </div>
                    </div>
                </Grid>                
                
                }
            </Grid>

        </div>
    );
}

export default HomepageFeatureZone;


 