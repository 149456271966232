import MainMenuCategories from "./mainMenuCategories";
import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Box, Drawer, List, ListItem, ListItemText, Stack, ListItemButton, Divider, Table, TableBody, TableRow, TableCell, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '../../../img/menuIcon.svg';
import QuickSearch from './quickSearch';
import logoImg from '../../../img/logo.gif';
import accountImg from '../../../img/icon_account.svg';
import cartImg from '../../../img/icon_cart.svg';
import helpImg from '../../../img/icon_help.svg';
import helpBlueImg from '../../../img/icon_help_blue.png';
import searchImgMob from '../../../img/search2.svg';
import orderImg from '../../../img/icon_order.png';
import frequentlyPurchasedImg from '../../../img/icon_frequently_purchased.png';
import programsImg from '../../../img/icon_programs.png';
import templatesImg from '../../../img/icon_template.png';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useAppUrl from "../../hooks/useAppUrl";
import usePermissions from "../../hooks/usePermissions";
import { MenuItems, Permissions } from "../../models/constants";
import useMenuItems from "../../hooks/useMenuItems";
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import React from "react";
import MiniShoppingCart from "../../pages/home/miniShoppingCart";
import dataService from "../../services/dataService";
import { MiniShoppingCartModel } from "../../models/product/types";
import PageLoading from "../shared/pageLoading";
import { useDispatch } from 'react-redux';
import { openShipToDialog } from '../../store/slices/shiptoSlice';
 


const MainMenu: React.FC = () => {
    const {
        isNEDivision
    } = useSelector((state: RootState) => state.warehouseState.warehouseInfo);

    const {
        cartItems,
        accountMenu,
        menu
    } = useSelector((state: RootState) => state.userState.user);

    const {
        warehouseCode,
    } = useSelector((state: RootState) => state.warehouseState.selectedWarehouse);

    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state: RootState) => state.userState.isAuthenticated);
    const isAdmin = useSelector((state: RootState) => state.userState.user.isAdmin);
    const isInternal = useSelector((state: RootState) => state.userState.user.isInternal);
    const hasMostPurchased = useSelector((state: RootState) => state.userState.user.hasMostPurchased);
    const defaultShipTo = useSelector((state: RootState) => state.userState.user.defaultShipTo);
    const userFirstName = useSelector((state: RootState) => state.userState.user.firstName);
    const userEmail = useSelector((state: RootState) => state.userState.user.email);
    const [firstTimeMenuOpened, setFirstTimeMenuOpened] = useState<boolean>(true);
    const [shoppingCart, setShoppingCart] = useState<MiniShoppingCartModel>();
    const [cartItemsCount, setCartItemsCount] = useState<number>(cartItems ?? 0);

    useEffect(() => {
        setFirstTimeMenuOpened(true);

        if (isAuthenticated && !isAdmin && !isInternal) {
            const getShoppingCart = async () => {
                await fetchShoppingCart();
            };
            getShoppingCart();
        }
    }, [window.location.pathname]);

    const navigate = useNavigate();

    const [activeMenu, setActiveMenu] = useState<null | number>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const closeMenuTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const openMenuTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const fetchShoppingCart = async () => {
        const response = await dataService.getMiniShoppingCart();
            if (response.success === true) {    
                var data = response.data;
                setShoppingCart(data);
             }
             else {
                console.error('Error fetching shopping cart');
            }
    }

    const handleMenuOpen = (menuId: number) => (event: React.MouseEvent<HTMLElement>) => {

        // Focus menu on open (to avoid anchoring other elements on the page)
        openMenuTimeoutRef.current = setTimeout(() => {
            document.body.setAttribute('tabindex', '-1'); // Ensure body is focusable
            document.body.focus();  // Focus on the body when hovering the menu

            // Clear any existing timeout
            clearTimeout(closeMenuTimeoutRef.current!);

            setActiveMenu(menuId);

            if (menuId !== 0) {
                document.body.classList.remove('menuFullWidth');
            }

            setFirstTimeMenuOpened(false);

        }, firstTimeMenuOpened ? 500 : 0);

    };

    const handleMenuClose = (menuId: number, forceClose: boolean) => {
        if (openMenuTimeoutRef.current)
            clearTimeout(openMenuTimeoutRef.current!);

        closeMenuTimeoutRef.current = setTimeout(() => {
            if (activeMenu === menuId) {
                // Prevent closing when Category submenu item is clicked
                if (forceClose || menuId !== 0) {
                    setActiveMenu(null);
                    document.body.classList.remove('menuFullWidth');
                }
            }
        }, 150);  // Add a slight delay before closing the menu
    };

    const handleMenuCloseOnClick = (menuId: number, forceClose: boolean) => {
        if (openMenuTimeoutRef.current)
            clearTimeout(openMenuTimeoutRef.current!);
        if (activeMenu === menuId) {
            // Prevent closing when Category submenu item is clicked
            if (forceClose || menuId !== 0) {
                setActiveMenu(null);
                document.body.classList.remove('menuFullWidth');
            }
        }
    };

    const cancelCloseMenu = () => {
        // Cancel closing if user hovers back in
        clearTimeout(closeMenuTimeoutRef.current!);
    };

    const handleSubcategoryOpened = (opened: boolean) => {
        if (opened) {
            document.body.classList.add('menuFullWidth');
        } else {
            document.body.classList.remove('menuFullWidth');
        }
    }

    const renderMenu = (menuId: number, anchorEl: HTMLElement | null, toUpperCase: boolean, items: { isVisible: boolean, label: string, link: string }[]) => {
        return (

            <Menu
                className="mainMenuExpand"
                anchorEl={anchorEl}
                open={activeMenu === menuId}
            >
                {items.filter(x => x.isVisible).map((item, index) => (
                    <div key={index}>
                        <MenuItem sx={{ color: "#494440" }}
                            onClick={() => handleMenuCloseOnClick(menuId, false)}
                            onMouseEnter={cancelCloseMenu}
                            onMouseLeave={() => handleMenuClose(menuId, true)}>
                            {menuId === 0 && activeMenu === 0
                                ? (
                                    <MainMenuCategories warehouseCode={warehouseCode} onSubcategoryOpened={handleSubcategoryOpened} />
                                )
                                : menuId === 0 && activeMenu !== 0
                                    ? (
                                        <></>
                                    ) : (
                                        <Link to={item.link} title={item.label}>{toUpperCase ? item.label.toUpperCase() : item.label}</Link>
                                    )}
                        </MenuItem>
                    </div>
                ))}
            </Menu>
        )
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const location = useLocation();

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        border: "0px solid pink",
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const { getAppUrl, getAppGlobal } = useAppUrl();

    const hasPermission = usePermissions();
    const { hasCategory, hasSubCategory, shopProductsVisible } = useMenuItems();

    const [mobileSubItemOpened, setMobileSubItemOpened] = useState<number>(0);

    const mobileScrollToTopRef = useRef<HTMLDivElement | null>(null);

    const openMobileMenuItem = (menuItemNumber: number) => {
        setMobileSubItemOpened(menuItemNumber);

        if (mobileScrollToTopRef !== null && mobileScrollToTopRef.current !== null)
            mobileScrollToTopRef.current.scrollTo(0, 0);
    }

    const toggleDrawer = (open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open)
        openMobileMenuItem(0);
    };

    let impactRewardsBranches: string[] = (process.env.REACT_APP_IMPACT_REWARDS_BRANCHES ?? "").split(',').map(item => item.toLowerCase());
    let impactRewardsBranchesToExclude: string[] = (process.env.REACT_APP_IMPACT_REWARDS_BRANCHES_TO_EXCLUDE ?? "").split(',').map(item => item.toLowerCase());
    let shouldExcludeImpactRewardBranch = impactRewardsBranchesToExclude.includes((warehouseCode ?? "").toLowerCase());
    let hasImpactRewards: boolean = impactRewardsBranches.includes((warehouseCode ?? "").toLowerCase()) && !shouldExcludeImpactRewardBranch;

    const mobileMenuItems = () => (
        <div role="presentation" ref={mobileScrollToTopRef}>
            <List sx={{ p: 3 }}>
                {mobileSubItemOpened !== 0 &&
                    <ListItem onClick={() => openMobileMenuItem(0)}>
                        <KeyboardArrowLeft style={{ fontSize: '40px', marginLeft: '-40px' }}></KeyboardArrowLeft><span>BACK</span>
                    </ListItem>
                }
                {hasMostPurchased && mobileSubItemOpened === 0 &&
                    <ListItem>
                        <Link to={getAppUrl("/ProductListItem/FrequentlyPurchasedTemplate")}>
                            <ListItemText className="sidebarIconLink"><img src={frequentlyPurchasedImg} alt="MY MOST PURCHASED"></img> MY MOST PURCHASED</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasCategory(MenuItems.MyOrdersQuotes) && mobileSubItemOpened === 0 &&
                    <ListItem onClick={() => openMobileMenuItem(1)}>
                        <ListItemText className="sidebarIconLink"><img src={orderImg} alt="MY ORDERS & QUOTES"></img> MY ORDERS & QUOTES</ListItemText>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.Orders) && mobileSubItemOpened === 1 &&
                    <ListItem>
                        <Link to={getAppUrl("/order/history?tab=orders")}>
                            <ListItemText className="sidebarIconLink"><img src={orderImg} alt="ORDERS"></img> ORDERS</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.OpenQuotes) && mobileSubItemOpened === 1 &&
                    <ListItem>
                        <Link to={getAppUrl("/order/history?tab=quotes")}>
                            <ListItemText className="sidebarIconLink"><img src={orderImg} alt="OPEN QUOTES"></img> OPEN QUOTES</ListItemText>
                        </Link>
                    </ListItem>
                }
                {isAuthenticated && hasSubCategory(MenuItems.HoverMaterialListQuote) && mobileSubItemOpened === 1 &&
                    <ListItem>
                        <Link to={getAppUrl("/order/history?tab=hoverQuotes")}>
                            <ListItemText className="sidebarIconLink"><img src={orderImg} alt="HOVER MATERIAL LIST QUOTE"></img> HOVER MATERIAL LIST QUOTE</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasCategory(MenuItems.TemplatesEstimates) && mobileSubItemOpened === 0 &&
                    <ListItem onClick={() => openMobileMenuItem(2)}>
                        <ListItemText className="sidebarIconLink"><img src={templatesImg} alt="TEMPLATES & ESTIMATES"></img> TEMPLATES & ESTIMATES</ListItemText>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.Templates) && mobileSubItemOpened === 2 &&
                    <ListItem>
                        <Link to={getAppUrl("/ProductList/Public")}>
                            <ListItemText className="sidebarIconLink"><img src={templatesImg} alt="TEMPLATES"></img> TEMPLATES</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.HomeownerEstimates) && mobileSubItemOpened === 2 &&
                    <ListItem>
                        <Link to={getAppUrl("/Quote/List")}>
                            <ListItemText className="sidebarIconLink"><img src={templatesImg} alt="HOMEOWNER ESTIMATES"></img> HOMEOWNER ESTIMATES</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasCategory(MenuItems.CustomerPrograms) && mobileSubItemOpened === 0 &&
                    <ListItem onClick={() => openMobileMenuItem(3)}>
                        <ListItemText className="sidebarIconLink"><img src={programsImg} alt="IMPACT PROGRAMS"></img> IMPACT PROGRAMS</ListItemText>
                    </ListItem>
                }
                {hasCategory(MenuItems.CustomerPrograms) && mobileSubItemOpened === 3 &&
                    <ListItem>
                        <Link to={"/impact-programs/impact-programs-overview"}>
                            <ListItemText className="sidebarIconLink"><img src={programsImg} alt="PROGRAM OVERVIEW"></img> PROGRAM OVERVIEW</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.ImpactRewards) && mobileSubItemOpened === 3 && (isNEDivision || isAdmin || hasImpactRewards) &&
                    <ListItem>
                        <Link to={"/impact-programs/impact-rewards"}>
                            <ListItemText className="sidebarIconLink"><img src={programsImg} alt="IMPACT REWARDS"></img> IMPACT REWARDS</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.LoyaltyLink) && mobileSubItemOpened === 3 &&
                    <ListItem>
                        <Link to={"/impact-programs/loyalty-link"}>
                            <ListItemText className="sidebarIconLink"><img src={programsImg} alt="LOYALTY LINK"></img> LOYALTY LINK</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.MarketingServices) && mobileSubItemOpened === 3 &&
                    <ListItem>
                        <Link to={"/impact-programs/lansing-marketing-services"}>
                            <ListItemText className="sidebarIconLink"><img src={programsImg} alt="MARKETING SERVICES"></img>MARKETING SERVICES</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.FinancingSolutions) && mobileSubItemOpened === 3 &&
                    <ListItem>
                        <Link to={"/impact-programs/financing-solutions"}>
                            <ListItemText className="sidebarIconLink"><img src={programsImg} alt="FINANCING SOLUTIONS"></img> FINANCING SOLUTIONS</ListItemText>
                        </Link>
                    </ListItem>
                }
                {hasSubCategory(MenuItems.LeadGeneration) && mobileSubItemOpened === 3 &&
                    <ListItem>
                        <Link to={"/impact-programs/lead-generation"}>
                            <ListItemText className="sidebarIconLink"><img src={programsImg} alt="LEAD GENERATION"></img> LEAD GENERATION</ListItemText>
                        </Link>
                    </ListItem>
                }
                {shopProductsVisible() && mobileSubItemOpened === 0 &&
                    <ListItem>
                        <ListItemText><h2>Shop Products</h2></ListItemText>
                    </ListItem>
                }
                {shopProductsVisible() && mobileSubItemOpened === 0 &&
                    <ListItem>
                        <div className="mobileMenuItems">
                            {isDrawerOpen ? <MainMenuCategories warehouseCode={warehouseCode} onSubcategoryOpened={handleSubcategoryOpened} /> : <></>}
                        </div>
                    </ListItem>
                }
            </List>
            {mobileSubItemOpened === 0 &&
                <List>
                    <ListItem className="mobileHelpItem" onClick={() => openMobileMenuItem(4)}>
                        <ListItemText style={{ justifyContent: "right" }}>
                            <img src={helpBlueImg} alt="HELP" style={{}}></img>
                            <Typography>HELP</Typography>
                            <ArrowRightAltIcon></ArrowRightAltIcon>
                        </ListItemText>
                    </ListItem>
                </List>
            }
            {mobileSubItemOpened === 4 &&
                <ListItem>
                    <Link to={process.env.REACT_APP_WEB_URL + "/common/contactus"}>
                        <ListItemText className="sidebarIconLink"><img src={programsImg} alt="CONTACT US"></img> CONTACT US</ListItemText>
                    </Link>
                </ListItem>
            }
            {mobileSubItemOpened === 4 &&
                <ListItem>
                    <Link to={process.env.REACT_APP_WEB_URL + "/faq"}>
                        <ListItemText className="sidebarIconLink"><img src={programsImg} alt="FAQ"></img> FAQ</ListItemText>
                    </Link>
                </ListItem>
            }
        </div>
    );

    //MOBILE SEARCH
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    let customerProgramsMenuItems = [];

    if (isAuthenticated) {
        customerProgramsMenuItems = menu.menuItems
            .filter(x => x.name === MenuItems.CustomerPrograms)
            .map(item => item.subCategories)
            .flat()
            .map(x => ({ isVisible: true, label: x.name, link: x.seName.replace('/' + process.env.REACT_APP_STOREFRONT_SUBDIRECTORY!, "") }));
    } else {
        customerProgramsMenuItems = [
            { isVisible: hasSubCategory(MenuItems.ImpactRewards), label: "Impact Rewards", link: "/impact-programs/impact-rewards" },
            { isVisible: hasSubCategory(MenuItems.LoyaltyLink), label: "Loyalty Link", link: "/impact-programs/loyalty-link" },
            { isVisible: hasSubCategory(MenuItems.MarketingServices), label: "Marketing Services", link: "/impact-programs/lansing-marketing-services" },
            { isVisible: hasSubCategory(MenuItems.FinancingSolutions), label: "Financing Solutions", link: "/impact-programs/financing-solutions" },
            { isVisible: hasSubCategory(MenuItems.LeadGeneration), label: "Lead Generation", link: "/impact-programs/lead-generation" }
        ];
    }

    let helpMenuItems = [
        { isVisible: true, label: "Contact us", link: process.env.REACT_APP_WEB_URL + "/common/contactus" },
        { isVisible: true, label: "FAQ", link: process.env.REACT_APP_WEB_URL + "/faq" },
        { isVisible: true, label: "Return Policy", link: process.env.REACT_APP_WEB_URL + "/return-privacy" },
    ];

    if (!isAdmin && (!isNEDivision || shouldExcludeImpactRewardBranch))
        customerProgramsMenuItems = customerProgramsMenuItems.filter(x => (hasImpactRewards || (x.label !== "Impact Rewards")));

    // ACCOUNT MENU
    const [userAccountMenu, setUserAccountMenu] = React.useState<HTMLButtonElement | null>(null);
    const handleAccountMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserAccountMenu(event.currentTarget);
    };
    const handleClose = () => {
        setUserAccountMenu(null);
    };
    const open = Boolean(userAccountMenu);
    const id = open ? 'simple-popover' : undefined;

    const CustomBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-dot': {
            top: '5px',
            right: '7px',
        },
    }));
    // ACCOUNT MENU

    useEffect(() => {
        setActiveMenu(null);
        document.body.classList.remove('menuFullWidth');
        setIsDrawerOpen(false);
        setIsOpen(false);
    }, [location]);

    const dispatchOpenShipToDialog = () => {
        dispatch(openShipToDialog());
        handleClose();
    }

    const renderAccountPopover = () => (
        <Popover id={id}
            open={open}
            anchorEl={userAccountMenu}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <List>
                {accountMenu?.myDashboardVisible && (
                    <ListItem disablePadding>
                        <ListItemButton sx={{py:0}} component="a" href={getAppGlobal("/customer/dqdashboard")}>
                            <ListItemText primary="My Dashboard" />
                        </ListItemButton>
                    </ListItem>
                )}
                {accountMenu?.onlineBillPayVisible && (
                    <ListItem disablePadding>
                        <ListItemButton sx={{py:0}} component="a" href={getAppGlobal("/billtrust")}>
                            <ListItemText primary="Online Bill Pay" />
                        </ListItemButton>
                    </ListItem>
                )}
                {accountMenu?.customerAccountVisible && (
                    <ListItem disablePadding>
                        <ListItemButton sx={{py:0}} component="a" href={getAppGlobal("/customer/info")}>
                            <ListItemText primary="Customer Account" />
                        </ListItemButton>
                    </ListItem>
                )}
                {accountMenu?.userProfileVisible && (
                    <ListItem disablePadding>
                        <ListItemButton sx={{py:0}} component="a" href={getAppGlobal("/customer/profile")}>
                            <ListItemText primary="User Profile" />
                        </ListItemButton>
                    </ListItem>
                )}
                {accountMenu?.manageUsersVisible && (
                    <ListItem disablePadding>
                        <ListItemButton  sx={{py:0}}component="a" href={getAppGlobal("/customer/users")}>
                            <ListItemText primary="Manage Users" />
                        </ListItemButton>
                    </ListItem>
                )}
                {accountMenu?.orderNotificationsVisible && (
                    <ListItem disablePadding>
                        <ListItemButton sx={{py:0}} component="a" href={getAppGlobal("/customer/ordernotifications")}>
                            <ListItemText primary="Order Notifications" />
                        </ListItemButton>
                    </ListItem>
                )}
                {!isInternal && !isAdmin && accountMenu?.shipToVisible && (
                    <ListItem disablePadding>
                        <ListItemButton component="a" href="#">
                            <ListItemText onClick={dispatchOpenShipToDialog} primary={<b>{`ShipTo: ${defaultShipTo}`}</b>} />
                        </ListItemButton>
                    </ListItem>
                )}
                <ListItem disablePadding>
                    <ListItemButton sx={{py:0}} component="a" href={getAppGlobal("/logout")}>
                        <ListItemText primary="Log Out" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Popover>
    );


    // CART MENU
    const [cartMenuAnchor, setCartMenuAnchor] = useState<null | HTMLElement>(null);
    const isCartMenuOpen = Boolean(cartMenuAnchor);

    const handleCartClose = () => {    
        setCartMenuAnchor(null);
    };

    const handleCartItemsCountChange = (newCartItemsCount: number) => {    
        setCartItemsCount(newCartItemsCount);
    };

    const toggleCartMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCartMenuAnchor(event.currentTarget);
    };

    const closeCartMenu = () => {
        setCartMenuAnchor(null);
    };

    return (
        <>
            <AppBar position="static" className="mainHeaderHolder"
                sx={{
                    backgroundColor: 'white',
                    borderBottom: "1px solid #eaeaea",
                    px: 0,
                    py: 1,
                }}
                elevation={0}>
                <Toolbar>
                    {isMobile ? (
                        <>
                            <div>
                                <IconButton

                                    edge="start"
                                    color="inherit"
                                    sx={{ color: "#939393" }}
                                    aria-label="menu"
                                    onClick={toggleDrawer(true)}>
                                    <img src={MenuIcon} alt="Menu" />
                                </IconButton>
                                <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}
                                    classes={{ paper: "mobileFullWidthDrawer" }}>
                                    {mobileSubItemOpened === 0 &&
                                        <DrawerHeader>
                                            <IconButton onClick={toggleDrawer(false)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </DrawerHeader>
                                    }
                                    {mobileSubItemOpened === 0 && isAuthenticated &&
                                        <Typography variant="h6" sx={{ flexGrow: 1, overflowWrap: "anywhere", ml: 2, mr: 2 }}>
                                            Hello, <b>{userFirstName !== null ? userFirstName : userEmail}!</b>
                                        </Typography>
                                    }
                                    {mobileMenuItems()}
                                </Drawer>
                            </div>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                <Link to={isAuthenticated ? process.env.REACT_APP_WEB_URL ?? "/" : "/"}>
                                    <img src={logoImg} alt='Lansing Logo' height={50}></img>
                                </Link>
                            </Typography>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                                <Button onClick={handleToggle} sx={{ minWidth: "25px", p: 0 }} variant="text">
                                    <img src={searchImgMob} alt="Search" />
                                </Button>
                                {isAuthenticated ?
                                    (
                                        <>
                                            <Button variant="text" onClick={handleAccountMenuClick}
                                                sx={{ minWidth: "25px", p: 0, m: 0 }}>
                                                <img src={accountImg} alt="Account"></img>
                                            </Button>
                                            {renderAccountPopover()}
                                            {hasPermission(Permissions.EnableShoppingCart) &&
                                                <Link to={getAppUrl("/cart")}>
                                                    <img src={cartImg} alt="Cart"></img>
                                                </Link>
                                            }
                                        </>
                                    )
                                    : <Button className="blueButtonOutline" style={{ marginLeft: "20px" }} href={process.env.REACT_APP_WEB_URL + "/quicklogin"}>LOG IN</Button>
                                }
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Typography variant="h6" sx={{ flexGrow: 1, zIndex: "1500", marginRight : "25px", maxWidth: "fit-content" }}>
                                <Link to={isAuthenticated ? process.env.REACT_APP_WEB_URL + "/lbpcustomer" : "/"}>
                                    <img src={logoImg} alt='Lansing Logo' height={50}></img>
                                </Link>
                            </Typography>
                            <Box sx={{ flexGrow: 2, textAlign: "left", zIndex: "1500" }}>
                                {shopProductsVisible() && (
                                    <>
                                        <Button color="inherit" className="mainMenuButtons mainMenuButtonsResponsive mainMenuButtonShopProducts"
                                            onMouseOver={handleMenuOpen(0)}
                                            onMouseEnter={cancelCloseMenu}
                                            onMouseLeave={() => handleMenuClose(0, true)}>
                                            Shop Products
                                        </Button>
                                        {renderMenu(0, activeMenu === 0 ? document.querySelector<HTMLElement>('.mainMenuButtonShopProducts') : null, false, [
                                            { isVisible: true, label: "Categories", link: "" }
                                        ])}
                                    </>
                                )}
                                {hasCategory(MenuItems.CustomerPrograms) && (
                                    <>
                                        <Button color="inherit" className={`mainMenuButtons mainMenuButtonsResponsive mainMenuCustomerPrograms ${activeMenu === 1 ? "button-active" : ""}`}
                                            onMouseOver={handleMenuOpen(1)}
                                            onMouseEnter={cancelCloseMenu}
                                            onMouseLeave={() => handleMenuClose(1, true)}
                                            onClick={() => {
                                                if (location.pathname !== "/impact-programs/impact-programs-overview") {
                                                    handleMenuCloseOnClick(1, false);
                                                    navigate("/impact-programs/impact-programs-overview");
                                                }
                                            }}
                                            title="IMPACT Programs">
                                            IMPACT Programs
                                        </Button>
                                        {renderMenu(1, activeMenu === 1 ? document.querySelector<HTMLElement>('.mainMenuCustomerPrograms') : null, true, customerProgramsMenuItems)}
                                    </>
                                )}
                                {hasCategory(MenuItems.TemplatesEstimates) && (
                                    <>
                                        <Button color="inherit" className={`mainMenuButtons mainMenuButtonsResponsive mainMenuCustomerTemplates ${activeMenu === 2 ? "button-active" : ""}`}
                                            onMouseOver={handleMenuOpen(2)}
                                            onMouseEnter={cancelCloseMenu}
                                            onMouseLeave={() => handleMenuClose(2, true)}>
                                            Templates & Estimates
                                        </Button>
                                        {renderMenu(2, activeMenu === 2 ? document.querySelector<HTMLElement>('.mainMenuCustomerTemplates') : null, true, [
                                            { isVisible: hasSubCategory(MenuItems.Templates), label: "Templates", link: getAppUrl("/ProductList/Public") },
                                            { isVisible: hasSubCategory(MenuItems.HomeownerEstimates), label: "Homeowner Estimates", link: getAppUrl("/Quote/List") },
                                        ])}
                                    </>
                                )}
                                {hasCategory(MenuItems.MyOrdersQuotes) && (
                                    <>
                                        <Button color="inherit" className={`mainMenuButtons mainMenuButtonsResponsive mainMenuCustomerMyOrders ${activeMenu === 3 ? "button-active" : ""}`}
                                            onMouseOver={handleMenuOpen(3)}
                                            onMouseEnter={cancelCloseMenu}
                                            onMouseLeave={() => handleMenuClose(3, true)}>
                                            My Orders & Quotes
                                        </Button>
                                        {renderMenu(3, activeMenu === 3 ? document.querySelector<HTMLElement>('.mainMenuCustomerMyOrders') : null, true, [
                                            { isVisible: hasSubCategory(MenuItems.Orders), label: "Orders", link: getAppUrl("/order/history?tab=orders") },
                                            { isVisible: hasSubCategory(MenuItems.OpenQuotes), label: "Open Quotes", link: getAppUrl("/order/history?tab=quotes") },
                                            { isVisible: isAuthenticated && hasSubCategory(MenuItems.HoverMaterialListQuote), label: "HOVER MATERIAL LIST QUOTE", link: getAppUrl("/order/history?tab=hoverQuotes") }
                                        ])}
                                    </>
                                )}
                            </Box>
                            {hasPermission(Permissions.EnableProductBrowsing) && (
                                <Box sx={{ flexGrow: 1, maxWidth: "300px", zIndex: "1500" }}>
                                    <QuickSearch />
                                </Box>
                            )}
                            <Box sx={{ flexGrow: 1, textAlign: "right", zIndex: "1500" }}>
                                <Button color="inherit" className="mainMenuButtonThumbs"
                                    onClick={handleMenuOpen(4)}
                                    onMouseEnter={cancelCloseMenu}
                                    onMouseLeave={() => handleMenuClose(4, true)}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <img src={helpImg} width={23} height={23} style={{ margin: "0 auto" }} alt="Help"></img>
                                        <span style={{ color: "#494440", fontSize: "11px", textTransform: "none" }}>Help</span>
                                    </div>
                                </Button>
                                {renderMenu(4, activeMenu === 4 ? document.querySelector('.mainMenuButtonThumbs') : null, false, helpMenuItems)}

                                {isAuthenticated
                                    ?
                                    <>
                                        <Button variant="text" onClick={handleAccountMenuClick}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <img src={accountImg} width={23} height={23} style={{ margin: "0 auto" }} alt="Account"></img>
                                                <span style={{ color: "#494440", fontSize: "11px", textTransform: "none" }}>Account</span>
                                            </div>
                                        </Button>
                                        {renderAccountPopover()}
                                        {hasPermission(Permissions.EnableShoppingCart) &&
                                            <>
                                                <Button color="inherit" onClick={toggleCartMenu}>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <CustomBadge color={cartItemsCount && cartItemsCount > 0 ? "error" : "default"} variant="dot">
                                                            <img src={cartImg} width={23} height={23} style={{ margin: "5px auto 0" }} alt="Cart"></img>
                                                        </CustomBadge>
                                                        <span className='headerCardText'>Cart<br />({cartItemsCount}) items</span>
                                                    </div>
                                                </Button>

                                                {!isAdmin && !isInternal &&
                                                    <Popover
                                                        className="zIndex2000"
                                                        anchorEl={cartMenuAnchor}
                                                        open={isCartMenuOpen}
                                                        onClose={closeCartMenu}
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        }}
                                                        slotProps={{
                                                            paper: {
                                                                sx: { width: 410, height: "auto", p: 2 },
                                                            }
                                                        }}
                                                    >
                                                        {shoppingCart ? (
                                                            <MiniShoppingCart
                                                                model={shoppingCart}
                                                                updateShoppingCart={(x: MiniShoppingCartModel) => setShoppingCart(x)}
                                                                onCartClose={handleCartClose}
                                                                onCartItemsCountChange={handleCartItemsCountChange} />
                                                        ) : (
                                                            <PageLoading />
                                                        )}
                                                    </Popover>
                                                }
                                            </>
                                        }
                                    </>
                                    : <Button className="blueButtonOutline" style={{ marginLeft: "20px" }} href={process.env.REACT_APP_WEB_URL + "/quicklogin"}>LOG IN</Button>
                                }
                            </Box>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            {hasPermission(Permissions.EnableProductBrowsing) && isOpen &&
                <Box mt={0} p={2} border={0}>
                    <QuickSearch />
                </Box>
            }


        </>
    );
}

export default MainMenu;
