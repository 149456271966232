import { autocomplete } from '@algolia/autocomplete-js';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch';
import { createRoot } from 'react-dom/client';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import defaultProductSmallImg from '../../../img/default-image_210.png';
import useAppUrl from '../../hooks/useAppUrl';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export interface WarehouseProps {
  warehouseName: string | null;
  warehouseCode: string | null;
}

let allowEmptyResultsDisplay = false;
const quickSearchKeystrokeDelay = 3;
const debounceSearchDelay = 500;
const appId = process.env.REACT_APP_ALGOLIA_ID ?? "";
const apiKey = process.env.REACT_APP_ALGOLIA_KEY ?? "";
const searchClient = algoliasearch(appId, apiKey);

const ProductClicked = (navigate: NavigateFunction, item: any, isAuthenticated: boolean) => {
  if (!isAuthenticated) {
    var productUrl = `/product?id=${item.id}`;
    navigate(productUrl);
    return;
  }
  var productUrl = `/ProductListProduct/LBPProductDetails?productId=${item.id}`;
  document.location.href = process.env.REACT_APP_WEB_URL + productUrl;  
}

const CategoryLvl1Clicked = (appUrlNavigate: any, item: any, searchQuery: any) => {
  var categoryName = item["categories.lvl1"];

  var categoryUrl = categoryName === "Windows"
        ? "/gallery?searchWindows=true"
        : categoryName === "Renaissance Patio Products" ? "/gallery?searchRenaissance=true"
        : `/gallery?categorylvl1=${encodeURIComponent(categoryName)}`;

  if (searchQuery) {
    appUrlNavigate(`${categoryUrl}&query=${encodeURIComponent(searchQuery)}`);
  }
  else {
    appUrlNavigate(categoryUrl);
  }
}

const BrandClicked = (appUrlNavigate: any, item: any, searchQuery: any) => {
  if (searchQuery) {
    appUrlNavigate(`/gallery?brand=${encodeURIComponent(item.manufacturername)}&query=${encodeURIComponent(searchQuery)}`);
  }
  else {
    appUrlNavigate(`/gallery?brand=${encodeURIComponent(item.manufacturername)}`);
  }
}

const ShouldPerformSearch = (searchTerm: any) => {
  return allowEmptyResultsDisplay || searchTerm.trim().length === 0 || searchTerm.trim().length >= quickSearchKeystrokeDelay;
}

const QuickSearch: React.FC = () => {
   const navigate = useNavigate();
   const {appUrlNavigate} = useAppUrl();   
   const isAuthenticated = useSelector((state: RootState) => state.userState.isAuthenticated);

    const touchStartRef = useRef({ x: 0, y: 0 });
    const touchThreshold = 10; // Define a small threshold to allow minor movements (e.g., tapping)

    const handleTouchStart = (event: React.TouchEvent) => {
      const touch = event.touches[0];

      // Capture start position
      touchStartRef.current = { x: touch.clientX, y: touch.clientY };
    };
    
    const handleProductTouchEnd = (event: React.TouchEvent, item: any) => {
      const touch = event.changedTouches[0];
      const touchEnd = { x: touch.clientX, y: touch.clientY };
    
      // Calculate the difference between start and end touch positions
      const deltaX = Math.abs(touchEnd.x - touchStartRef.current.x);
      const deltaY = Math.abs(touchEnd.y - touchStartRef.current.y);
    
      // Only consider it a tap if the movement is within the threshold (i.e., not a scroll)
      if (deltaX < touchThreshold && deltaY < touchThreshold) {
        // Prevent default to avoid accidental triggers
        event.preventDefault();
        event.stopPropagation();
        ProductClicked(navigate, item, isAuthenticated);
      }
    };

    const handleCategoryTouchEnd = (event: React.TouchEvent, item: any, query: string) => {
      const touch = event.changedTouches[0];
      const touchEnd = { x: touch.clientX, y: touch.clientY };
    
      // Calculate the difference between start and end touch positions
      const deltaX = Math.abs(touchEnd.x - touchStartRef.current.x);
      const deltaY = Math.abs(touchEnd.y - touchStartRef.current.y);
    
      // Only consider it a tap if the movement is within the threshold (i.e., not a scroll)
      if (deltaX < touchThreshold && deltaY < touchThreshold) {
        // Prevent default to avoid accidental triggers
        event.preventDefault();
        event.stopPropagation();
        CategoryLvl1Clicked(appUrlNavigate, item, query);
      }
    };

    const handleBrandTouchEnd = (event: React.TouchEvent, item: any, query: string) => {
      const touch = event.changedTouches[0];
      const touchEnd = { x: touch.clientX, y: touch.clientY };
    
      // Calculate the difference between start and end touch positions
      const deltaX = Math.abs(touchEnd.x - touchStartRef.current.x);
      const deltaY = Math.abs(touchEnd.y - touchStartRef.current.y);
    
      // Only consider it a tap if the movement is within the threshold (i.e., not a scroll)
      if (deltaX < touchThreshold && deltaY < touchThreshold) {
        // Prevent default to avoid accidental triggers
        event.preventDefault();
        event.stopPropagation();
        BrandClicked(appUrlNavigate, item, query);
      }
    };

    const productIndexName = process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX ?? "";
    const lansingCDNpath = process.env.REACT_APP_CDN_URL;

    const isExclusiveCustomer = useSelector((state: RootState) => state.userState.user.exclusiveCustomer);

    const {
      warehouseCode
    } = useSelector((state: RootState) => state.warehouseState.selectedWarehouse);

    let searchFilter = warehouseCode === null || warehouseCode === "" 
      ? (isExclusiveCustomer ? "visibleindividually:true" : "visibleindividually:true AND isexclusiveproduct:false")
      : (isExclusiveCustomer ? `visibleindividually:true AND warehouses:${warehouseCode}` : `visibleindividually:true AND isexclusiveproduct:false AND warehouses:${warehouseCode}`);
    let searchOptionalFilters = warehouseCode === null || warehouseCode === "" 
      ? undefined 
      : [
        `sales.lvl1:${warehouseCode}`,
        `sales.lvl2:${warehouseCode}`,
        `sales.lvl3:${warehouseCode}`,
        `sales.lvl4:${warehouseCode}`,
        `sales.lvl5:${warehouseCode}`,
        `sales.lvl6:${warehouseCode}`,
        `sales.lvl7:${warehouseCode}`,
        `sales.lvl8:${warehouseCode}`,
        `sales.lvl9:${warehouseCode}`,
        `sales.lvl10:${warehouseCode}`,
        `sales.lvl11:${warehouseCode}`,
        `sales.lvl12:${warehouseCode}`,
        `sales.lvl13:${warehouseCode}`,
        `sales.lvl14:${warehouseCode}`,
        `sales.lvl15:${warehouseCode}`
      ];

    const debouncedQuickSearchPromise = debounceQuickSearchPromise((items: any) => Promise.resolve(items), debounceSearchDelay);

    return (
        <Autocomplete
            placeholder="Search for products"
            searchClient={searchClient}
            openOnFocus={true}
            getSources={({ query }: any) => {
              return debouncedQuickSearchPromise([{
                  sourceId: 'products',
                  getItems() {
                    if (!ShouldPerformSearch(query)) 
                      return [];

                    return getAlgoliaResults({
                        searchClient,
                        queries: [
                          {
                            indexName: productIndexName,
                            query,
                            params: {
                              hitsPerPage: 5,
                              filters: searchFilter,
                              optionalFilters: searchOptionalFilters,
                              attributesToSnippet: ['sku:10', 'name:35'],
                              snippetEllipsisText: '-'
                            }
                          }
                        ]
                    });
                  },
                  templates: {
                    header() {
                      return (
                        <>
                          <span className="aa-SourceHeaderTitle aa-PopularProductsTitle">
                            <span className="aa-PopularProductsTitlePrefix">Popular </span>
                            <span>Products</span></span>
                          <div className="aa-SourceHeaderLine"></div>
                        </>
                      )
                    },
                    footer({ state }: any) {
                      let searchRedirect = `/gallery?query=${encodeURIComponent(state.query)}`;
                      return (
                        <div>
                          <button 
                            onClick={() => appUrlNavigate(searchRedirect)} 
                            className="see-all-products">See All Products</button>
                        </div>
                      );
                    },
                    item({ item, components }: any) {
                      let imageUrl = defaultProductSmallImg;
                      if (item.pictureperwarehouse != null && item.pictureperwarehouse.length > 0) {
                        let warehouseImage = item.pictureperwarehouse.find((x: any) => x.item1 != null && x.item1.toLowerCase() === (warehouseCode ?? '').toLowerCase())
                          if (warehouseImage != null)
                              imageUrl = `${lansingCDNpath}/${warehouseImage.item2}`;
                          else {
                            warehouseImage = item.pictureperwarehouse.find((x: any) => x.item1 === "")
                            if (warehouseImage != null)
                                imageUrl = `${lansingCDNpath}/${warehouseImage.item2}`;
                          }
                      }

                      return (
                        <div key={item.sku} className="aa-ItemWrapper popularProductHolder" 
                            onClick={(event) => {
                                event.preventDefault();  
                                event.stopPropagation();
                                ProductClicked(navigate, item, isAuthenticated);
                            }}
                            onTouchStart={(event) => handleTouchStart(event)}
                            onTouchEnd={(event) => handleProductTouchEnd(event, item)}
                            >
                            <div className="aa-ItemContent">
                            <div className="aa-ItemIcon aa-ItemIcon--alignTop">
                                    <img src={imageUrl}
                                    className="autocomplete-product-image" alt="" />
                            </div>
                            <div className="aa-ItemContentBody">
                                <div className="aa-ItemContentTitle">
                                    {components.Snippet({ hit: item, attribute: 'name' })}
                                </div>
                                {/* <div className="aa-ItemContentDescription">
                                    <b>SKU:</b> {components.Highlight({ hit: item, attribute: 'sku' })}
                                </div> */}
                            </div>
                            </div>
                        </div>
                      );
                    },
                  }
                },
                {
                  sourceId: "categories.lvl1",
                  getItems() {
                      if (!ShouldPerformSearch(query)) 
                          return [];
              
                      return getAlgoliaResults({
                          searchClient,
                          queries: [
                              {
                                  indexName: productIndexName,
                                  query,
                                  params: {
                                      maxValuesPerFacet: 5,
                                      filters: searchFilter,
                                      optionalFilters: searchOptionalFilters,
                                      facets: ["categories.lvl1", "categories.lvl2", "categories.lvl3", "categories.lvl4"]
                                  }
                              }
                          ],
                          transformResponse({ results }: any) {
                              let formatedFacets: any[] = [];
              
                              if (results[0].facets["categories.lvl1"]) {
                                  Object.entries(results[0].facets["categories.lvl1"]).forEach((element) => {
                                      formatedFacets.push({ "categories.lvl1": element[0], count: element[1] });
                                  });
                              }
                              return formatedFacets;
                          }
                      });
                  },
                  templates: {
                      header() {
                          return (
                            <>
                              <span className="aa-SourceHeaderTitle">Categories</span>
                              <div className="aa-SourceHeaderLine"></div>
                            </>
                          );
                      },
                      item({ item }: any) {
                          return (
                            <div 
                              onClick={(event) => {
                                  event.preventDefault();  
                                  event.stopPropagation();
                                  CategoryLvl1Clicked(navigate, item, query);
                              }}
                              onTouchStart={(event) => handleTouchStart(event)}
                              onTouchEnd={(event) => handleCategoryTouchEnd(event, item, query)}
                              >
                                <strong>{item["categories.lvl1"]}</strong> <em>({item.count})</em>
                            </div>
                          );
                      }
                  }
              },
              {
                sourceId: "manufacturername",
                getItems() {
                    if (!ShouldPerformSearch(query)) 
                        return [];

                    return getAlgoliaResults({
                        searchClient,
                        queries: [
                            {
                                indexName: productIndexName,
                                query,
                                params: {
                                    maxValuesPerFacet: 5,
                                    filters: searchFilter,
                                    optionalFilters: searchOptionalFilters,
                                    facets: ["manufacturername"]
                                }
                            }
                        ],
                        transformResponse({ results }: any) {
                            const formatedFacets: any[] = [];

                            if (results[0].facets["manufacturername"]) {
                              Object.entries(results[0].facets["manufacturername"]).forEach((element) => {
                                  formatedFacets.push({ "manufacturername": element[0], count: element[1] });
                              });
                            }
                            
                            return formatedFacets;
                        }
                    });
                },
                templates: {
                    header() {
                        return (
                          <>
                            <span className="aa-SourceHeaderTitle">Brands</span>
                            <div className="aa-SourceHeaderLine"></div>
                          </>
                        );
                    },
                    item({ item }: any) {
                        return (
                            <div 
                              onClick={(event) => {
                                  event.preventDefault();  
                                  event.stopPropagation();
                                  BrandClicked(navigate, item, query);
                              }}
                              onTouchStart={(event) => handleTouchStart(event)}
                              onTouchEnd={(event) => handleBrandTouchEnd(event, item, query)}
                              >
                              <strong>{item.manufacturername}</strong> <em>({item.count})</em>
                            </div>
                        );
                    }
                }
              }
            ])}}>      
        </Autocomplete>
    );
}

export function debounceQuickSearchPromise(fn: any, time: any) {
  let timerId: any = undefined;

  return function debounced(...args: any) {
      if (timerId) {
          clearTimeout(timerId);
      }

      return new Promise((resolve) => {
          timerId = setTimeout(() => resolve(fn(...args)), time);
      });
  };
}

export function Autocomplete(props: any) { 
  const navigate = useNavigate();
  const {appUrlNavigate} = useAppUrl();
  const containerRef = useRef<any>(null);
  const panelRootRef = useRef<any>(null);
  const rootRef = useRef<any>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render: () => {} },
      onSubmit(params) {
        appUrlNavigate(`/gallery?query=${encodeURIComponent(params.state.query)}`);
      },
      plugins: [
        createLocalStorageRecentSearchesPlugin({
          key: 'RECENT_SEARCH',
          limit: 5,
          transformSource({ source, onRemove }) {
              return {
                  ...source,
                  onSelect({ item, state }) {
                    appUrlNavigate(`/gallery?query=${encodeURIComponent(item.label)}`);
                  },
                  templates: {
                      header() {
                          return (
                            <>
                              <span className="aa-SourceHeaderTitle">Your Recent Searches</span>
                              <div className="aa-SourceHeaderLine"></div>
                            </>
                          );
                      },
                      ...source.templates,
                      item(params) {
                          const { item } = params;
                          return (
                              <div key={item.id}>
                                  <span className="aa-ItemContentTitle">{item.id}</span>
                                  <button className="delete-button" 
                                    onClick={(event) => {
                                        event.preventDefault();  
                                        event.stopPropagation();
                                        onRemove(item.id);
                                    }}>
                                      <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0.975418 0.722092L9.30876 8.2871" stroke="#A9A9A9" strokeWidth="2"/>
                                          <path d="M0.691254 8.2871L9.02458 0.722092" stroke="#A9A9A9" strokeWidth="2"/>
                                      </svg>
                                  </button>
                              </div>
                          );
                      },
                  },
              };
          }
        }),
        createQuerySuggestionsPlugin({
          searchClient,
          indexName: process.env.REACT_APP_ALGOLIA_POPULAR_SEARCHES_INDEX ?? "",
          // limit: 5,
          transformSource({ source }) {
              return {
                  ...source,
                  getItems(params) {
                      if (!ShouldPerformSearch(params.state.query)) 
                          return [];
        
                      const items = source.getItems(params);
                      return items;
                  },
                  templates: {
                      header() {
                          return (
                              <div id="aa-QuerySuggestions-Wrapper">
                                  <span className="aa-SourceHeaderTitle">Popular Searches</span>
                                  <div className="aa-SourceHeaderLine" />
                              </div>
                          );
                      },
                      ...source.templates
                  },
                  onSelect({ item, state }) {
                    let url = item.url.toString()
                      .replace('/AlgoliaSearch/SearchCategory','')
                      .replace('/AlgoliaSearch/Search','');
                    navigate(`/gallery${url}`);
                  }
              }
          }
        })
      ],
      render({ children }: any, root: any) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props, navigate]);

  return <div ref={containerRef} />;
}

export default QuickSearch;