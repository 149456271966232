export const URLS = {
    CUSTOMER_PROFILE_URL: `CustomerProfile`,
    NEAREST_WAREHOUSE_URL: `GetNearestBranch`,
    WAREHOUSE_INFO_URL: `GetBranchInfo`,
    GET_ALL_BRANCHES_ORDERED_BY_LOCATION_URL: `GetAllWarehousesOrderedByLocation`,
    GET_ALL_BRANCHES: `GetAllBranches`,
    SET_SELECTED_WAREHOUSE: `SetSelectedWarehouse`,
    GET_SORTED_CATEGORIES: `GetSortedCategories`,
    GET_PRODUCT_DETAILS: 'GetProductDetails',
    GET_VARIANT_DETAILS: 'GetVariantDetails',
    GET_HEX_TO_COLOR_MAPPING: 'GetHexToColorMapping',
    GET_FEATURED_CATEGORIES: 'GetFeaturedCategories',
    GET_SHIP_TOS: 'GetShipTos',
    VALIDATE_SHIP_TO_CHANGE: 'ValidateShipToChange',
    SET_SELECTED_SHIP_TO: 'SetSelectedShipTo',
    GET_SECONDARY_USER_INFO: 'GetSecondaryUserInfo',
    GET_MINI_SHOPPING_CART: 'GetMiniShoppingCart',
    UPDATE_MINI_CART_ITEM_QUANTITY: 'UpdateMiniCartItemQty',
    DELETE_MINI_CART_ITEM: 'DeleteMiniCartItem',
    GET_FEATURED_CATEGORIES_VISIBILITIES: 'GetFeaturedCategoriesVisibilities',
};