import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box, Table, TableBody, TableRow, TableCell, TextField, Dialog, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import useAppUrl from '../../hooks/useAppUrl';
import { InventoryStatus, type MiniShoppingCartModel } from '../../models/product/types';
import dataService from '../../services/dataService';
import defaultProductImg from '../../../img/default-image_420.png';

export interface MiniShoppingCartProp {
    model?: MiniShoppingCartModel;
    updateShoppingCart: (model: MiniShoppingCartModel) => void;
    onCartClose: () => void;
    onCartItemsCountChange: (newCartItemsCount: number) => void;
}

const MiniShoppingCart: React.FC<MiniShoppingCartProp> = ({ model, updateShoppingCart, onCartClose, onCartItemsCountChange }) => {

    const { getAppUrl } = useAppUrl();
    const cartRef = useRef<HTMLDivElement>(null);
    const [shoppingCart, setShoppingCart] = useState<MiniShoppingCartModel>(model!);
    const [itemsQuantityMap, setItemsQuantityMap] = useState<{ [key: number]: number }>({});
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [currentShoppingCartItemToRemove, setCurrentShoppingCartItemToRemove] = useState<number>();    

    // useEffect(() => {
    //    setShoppingCart(model!);
    // }, []); 
    
    
    useEffect(() => {
        const quantityMap: { [key: number]: number } = {};
        model?.items?.forEach(item => {
            quantityMap[item.id] = item.quantity;
        });
        setItemsQuantityMap(quantityMap);

        const handleClickOutside = (event: MouseEvent) => {
            if (cartRef.current && !cartRef.current.contains(event.target as Node)) {
                closeCartMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); 

    const closeCartMenu = () => {
        onCartClose();
    };

    const handleItemQuantityChange = (shoppingCartItemId: number, quantity: number) => {
        if (quantity <= 0) {
            return;
        }
        itemsQuantityMap[shoppingCartItemId] = quantity;
        setItemsQuantityMap({ ...itemsQuantityMap });
        updateMiniCartItemQty(shoppingCartItemId, quantity);
    };

    const handleItemDeleteChange = async (shoppingCartItemId: number) => {
        setCurrentShoppingCartItemToRemove(shoppingCartItemId);
        setConfirmationMessage("Are you sure you want to remove selected item from the cart?");
        setConfirmationOpen(true); 
    }; 

    const handleConfirmationClose = async (confirmed: boolean, shoppingCartItemId: number) => {
        setConfirmationOpen(false);
        if (confirmed) {
            const response = await dataService.deleteMiniCartItem(shoppingCartItemId);
            if (response.success === true) {   
                var data = response.data;         
                setShoppingCart({ ...data }); 
                updateShoppingCart(data);      
                onCartItemsCountChange(data.items.length);
            }
            else {
                console.error('Error removing item from cart');
            }
        }        
    };
    
    const updateMiniCartItemQty = async (shoppingCartItemId: number, quantity: number) => {
        const response = await dataService.updateMiniCartItemQty(shoppingCartItemId, quantity);
            if (response.success === true) {    
            var data = response.data;         
            setShoppingCart({ ...data }); 
            updateShoppingCart(data);        
             }
             else {
                console.error('Error fetching shopping cart');
            }
    }
    
    return (
        <Box ref={cartRef}>
            <Box className="cartHeader">
                <Typography sx={{ fontWeight: "bold", mb: 1, color: "#494440", fontSize: "16" }}>
                    {!shoppingCart || !shoppingCart.items || shoppingCart.items.length === 0 ? "You have no items in your shopping cart." : "Items in cart"}
                </Typography>
                <Typography sx={{ fontWeight: "bold", mb: 1, color: "#494440", fontSize: "16", display: "none" }}>
                    Added to Cart
                </Typography>                
                <Button onClick={() => onCartClose()} variant="text" size="small" className='cartCloseButton'>
                    <CloseIcon sx={{color:"#62B5E5", fontSize:20}} />
                </Button>  
            </Box>
            {shoppingCart && shoppingCart.items && shoppingCart.items.length > 0 && (
                <>
                <div className='cardHolder'>
                <Box sx={{ my: 5, }} className="cartItemsBox">
                    {shoppingCart?.items?.map((item) => (
                        <div style={{position:"relative"}}>
                        <Box className="itemCartContainer">
                            <Box className="itemCartImage">
                                <Link to={getAppUrl(`/${item.productSeName}`)} title={item.picture.title}>
                                    <img alt={item.picture.alternateText}
                                         title={item.picture.title}
                                         style={{background:item.colorHex}}
                                         src={item.picture.imageUrl && item.picture.imageUrl.trim() !== '' ? `${process.env.REACT_APP_CDN_URL}/images/general/picture_${item.picture.imageUrl}` : defaultProductImg} />
                                </Link>
                            </Box>
                            <Box className="itemCartDesc">
                                <Box className="itemCartDescHeader">
                                    <Link to={getAppUrl(`/${item.productSeName}`)} title={item.productName} className='itemCartDescName'>
                                        {item.productName}
                                    </Link>                       
                                </Box>
                                <Table>
                                    <TableBody>
                                        
                                            {!item.isAvailable && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography className="cart-item-special fontSize14">Not available in branch</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {item.inventoryStatus === InventoryStatus.SpecialItem && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography className="cart-item-special fontSize14">Special Order</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {item.inventoryStatus === InventoryStatus.Stocked && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography className="color-green fontSize14" style={{ margin: 0 }}>Stocked</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {item.inventoryStatus === InventoryStatus.Available && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography className="color-green fontSize14" style={{ margin: 0 }}>Available</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {item.inventoryStatus === InventoryStatus.InNetwork && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography className="color-green fontSize14" style={{ margin: 0 }}>Stocked in Network</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {item.inventoryStatus === InventoryStatus.ContactBranchForAvailability && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography className="cart-item-special fontSize14" style={{ margin: 0, color: 'black' }}>Contact branch for availability</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        
                                        <TableRow>
                                            <TableCell className="w90 color-gray">SKU:</TableCell>
                                            <TableCell className="color-gray" title={item.sku}>{item.sku}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="w90 color-gray">Unit price:</TableCell>
                                            <TableCell className="color-gray">{item.unitPriceString}</TableCell>
                                        </TableRow>
                                        {item.measure && item.measure.trim() !== "" && (
                                            <TableRow>
                                                <TableCell className="w90 color-gray">Measure:</TableCell>
                                                <TableCell className="color-gray">{item.measure}</TableCell>
                                            </TableRow>
                                        )}
                                        {item.color && item.color.trim() !== "" && (
                                            <TableRow>
                                                <TableCell className="w90 color-gray">Color:</TableCell>
                                                <TableCell className="color-gray">{item.color}</TableCell>
                                            </TableRow>
                                        )}
                                        {item.uom && item.uom.trim() !== "" && (
                                        <TableRow>
                                            <TableCell className="w90 color-gray">UOM:</TableCell>
                                            <TableCell className="color-gray">{item.uom}</TableCell>
                                        </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell className="w90 color-gray">Quantity:</TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Button variant="text" size="small" onClick={() => handleItemQuantityChange(item.id, itemsQuantityMap[item.id] - 1)} sx={{ minWidth: 30 }}>
                                                        <RemoveIcon  sx={{fontSize:16, color:"#494440", p:0}}/>
                                                    </Button>
                                                    <TextField
                                                        className="color-gray"
                                                        type="number"
                                                        value={itemsQuantityMap[item.id]}
                                                        onChange={(e) => handleItemQuantityChange(item.id, Number(e.target.value))}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ width: 30, textAlign: "center" }} />
                                                    <Button variant="text" size="small" onClick={() => handleItemQuantityChange(item.id, itemsQuantityMap[item.id] + 1)} sx={{ minWidth: 30 }}>
                                                        <AddIcon sx={{fontSize:16, color:"#494440", p:0}} />
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                        <div className="itemCardClose">
                            <Button onClick={() => handleItemDeleteChange(item.id)} variant="text" size="small" style={{ minWidth: 20, padding:0 }}><CloseIcon sx={{color:"#494440", fontSize:20}} /></Button>
                        </div>
                        </div>
                    ))}
                </Box>
                </div>
                <Box className="cartFooter">
                    <Typography sx={{ fontWeight: "bold", color: "#494440", fontSize: "16" }}>
                        Your Order Sub Total: {shoppingCart?.orderTotal}
                    </Typography>
                    <Link className="blueButton" to={getAppUrl("/cart")}>
                        View Cart
                    </Link>
                </Box>
                </>
            )}
            <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Typography>{confirmationMessage}</Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" color="primary" className='blueButtonOutline' onClick={() => handleConfirmationClose(true, currentShoppingCartItemToRemove!)} autoFocus>
                        YES
                    </Button>
                    <Button variant="outlined" color="primary" className='blueButtonOutline' onClick={() => handleConfirmationClose(false, currentShoppingCartItemToRemove!)}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
        
    );
}

export default MiniShoppingCart;
