
export interface ProductDetails {
    name: string | null;
    brand: string | null;
    type: string | null;
    variants: ProductDetailsVariant[];
    category: string | null;
    fullDescription: string;
    embeddedVideo: string | null;
    attachments: any[]; // Replace `any` with the appropriate type if known
    customWindows: boolean; // Replace `any` with the appropriate type if known    
    customRenaissance: boolean;
    customWindowsPrice: string | null;
    variantDetails: VariantDetails | null;
    pictures: string[];
    windowGallery: string[];
    sku: string | null;
    inventoryStatus: string | null;
    specifications: ProductDetailsSpecification[];
    overlayRGB: string | null;
    stockedColorRgb: string | null;
    stockedColor: string | null;
    stockedMeasure: string | null;
    stockedVariantsCount: number;
    specialColorRgb: string | null;
    specialColor: string | null;
    specialMeasure: string | null;
    specialVariantsCount: number;
}

export interface VariantDetails {
    id: number;
    pictures: string[];
    sku: string | null;
    overlayRGB: string | null;
    inventoryStatus: string | null;
    specifications: ProductDetailsSpecification[]; 
}

export interface ProductDetailsVariant {
    id: number;
    name: string;
    colorSquaresRGB: string;
    inventoryStatus: string;
}

export interface ProductDetailsSpecification {
    name: string | null;
    value: string | null;
}

export interface ProductDetailsDocument {
    attachmentId: number | null;
    fileName: string | null;
}

export interface MiniShoppingCartModel {
    items: ShoppingCartItem[];
    totalProducts: number;
    subTotal: string;
    displayCheckoutButton: boolean;
    orderTotalValue: number;
    orderTotal: string;
    orderTotalDiscount: string;
}

export interface ShoppingCartItem {
    id: number;
    productId: number;
    productName: string;
    productSeName: string;
    quantity: number;
    unitPrice: number;
    unitPriceString: string;
    picture: Picture;
    uom: string;
    color: string;
    colorHex: string;
    measure: string;
    sku: string;
    isProductionItem: boolean;
    isAvailable: boolean;
    inventoryStatus: InventoryStatus;
}

export interface Picture {
    imageUrl: string;
    title: string;
    alternateText: string;
}

export enum InventoryStatus {
    InNetwork = 2,
    SpecialItem = 3,
    Stocked = 5,
    Available = 6,
    ContactBranchForAvailability = 7
}

export interface UpdateMiniCartDto {
    warnings: string[];
}
