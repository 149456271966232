import React from "react";
import { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import Error404Page from "../../pages/errors/404";
import SeoIgnore from "../../utils/seoIgnore";
import '@builder.io/widgets';

interface BuilderIOPageProps {
  data?: any; //should contain the data for the page
  context?: any; //should contain actions (functions) that can be called in the page
  url?: string,
  onStateChange?: (state: any) => void;
}

const BuilderIOPage: React.FC<BuilderIOPageProps> = ({ data = {}, context, url, onStateChange }) => {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  const isAuthenticatedFromStore = useSelector((state: RootState) => state.userState.isAuthenticated);
  const [isAuthenticated, setIsAuthenticated] = React.useState(isAuthenticatedFromStore);

  const stateChangedHandler = (state: any) => {
      setIsAuthenticated(state.isAuthenticated);
      if (onStateChange) {
          onStateChange(state);
      }
  };

  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: url ?? window.location.pathname
            .replace("/" + process.env.REACT_APP_SUBDIRECTORY, "")
            .replace("/" + process.env.REACT_APP_STOREFRONT_SUBDIRECTORY, "")
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      // if the page title is found,
      // set the document title
      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, [url]);

  if (notFound && !isPreviewingInBuilder) {
    return <Error404Page />;
  }

  // return the page when foundhjjh   
  return (
    <div className={isAuthenticated ? "builder-page-width builder-page-width-logged" : "builder-page-width"}>
      <SeoIgnore></SeoIgnore>
      {/* Render the Builder page */}
      <BuilderComponent
        model="page"
        content={content!}
        onStateChange={stateChangedHandler}
        data={{ ...data, isAuthenticated : isAuthenticated }}
        context={context ? { ...context } : undefined}
      />
    </div>
  );
};

export default BuilderIOPage;
