import React from 'react';
import BuilderIOPage from '../../builderio/components/builderIOPage';
import useImpactPrograms from '../../hooks/useImpactPrograms';
import PageLoading from '../../components/shared/pageLoading';

const LansingMarketingServices: React.FC = () => {
    const { builderIOData, isLoading, stateChangedHandler } = useImpactPrograms();

    if (isLoading) {
        return <PageLoading />;
    }

    return (
        <BuilderIOPage
            data={builderIOData}
            onStateChange={stateChangedHandler}
            url="/impact-programs/lansing-marketing-services" />
    );
};

export default LansingMarketingServices;