import HomePageCategories from "./homepageCategories";
import HomepageProductTemplate from "./homepageProductTemplate";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import HomepageFeatureZone from "./homepageFeatureZone";
import PageTitle from "../../utils/pageTitle";
import homepageImpactProgramsLogo from '../../../img/homepage-impact-programs-logo.png';
import homepageImpactProgramsPlusImg from '../../../img/homepage-impact-programs-plus.png';
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import BuilderIOSection from "../../builderio/components/builderIOSection";
import { BuilderIOSections } from "../../models/constants";
import dataService from '../../services/dataService';
import HomepageSecondaryUser from './homepageSecondaryUser';
import { useEffect, useState } from "react";
import { FeaturedCategory } from "../../models/customer/types";


const Homepage: React.FC = () => {
  PageTitle(null); // Set page title

  const {
    warehouseName,
    warehouseCode
  } = useSelector((state: RootState) => state.warehouseState.selectedWarehouse);

  const warehouse = useSelector((state: RootState) => state.warehouseState.selectedWarehouse);
  const isAuthenticated = useSelector((state: RootState) => state.userState.isAuthenticated);
  const isSecondaryUser = useSelector((state: RootState) => state.userState.user.isSecondaryUser);

  const impactProgramsUrl = window.location.href + "/impact-programs/impact-programs-overview";

  const productTemplatesUrl = isAuthenticated
    ? "/ProductList/Public"
    : process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/ProductList/Public");

  const onlineBillPayVisible = useSelector((state: RootState) => state.userState.user.accountMenu?.onlineBillPayVisible);

  const [featuredCategories, setFeaturedCategories] = useState<{ [key: string]: FeaturedCategory }>({});

  useEffect(() => {
    const fetchFeaturedCategories = async () => {
        const response = await dataService.getFeaturedCategoriesVisibilities(warehouse?.warehouseCode ?? "", isAuthenticated);
        setFeaturedCategories(response.data);
    };

    fetchFeaturedCategories();
  }, [warehouseCode, isAuthenticated]);

  return (
    <>
      {isSecondaryUser &&
        <HomepageSecondaryUser />
      }
      <HomepageFeatureZone />
      <Container maxWidth="lg">
        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
          {/* <HomePageCategories /> */}
          <BuilderIOSection
            section={BuilderIOSections.FigmaSection}
            data={{
              warehouse: warehouse.warehouseCode,
              impactProgramsUrl: impactProgramsUrl,
              productTemplatesUrl: productTemplatesUrl,
              onlineBillPayVisible: onlineBillPayVisible ?? false,
              categories: featuredCategories
            }}
            filter={{ name: BuilderIOSections.TagFilterName, value: BuilderIOSections.FeaturedCategoriesFilterValue }} />
        </Grid>
      </Container>
    </>
  );
};

export default Homepage;
