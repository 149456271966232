import React, { useEffect, useRef, useState } from "react";
import { Warehouse } from "../../models/warehouse/types";

export interface StaticMapComponentProps {
    selectedWarehouseCode: string | null;
    warehouses: Warehouse[];
    longitudeCenterOffset: number;
}

const StaticMapComponent: React.FC<StaticMapComponentProps> = ({ warehouses, selectedWarehouseCode, longitudeCenterOffset }) => {
    const containerRef = useRef<HTMLDivElement> (null);
    const [containerSize, setContainerSize] = useState <{ width: number; height: number }> ({ width: 0, height: 0 });

    let selectedLatitude = warehouses.find(x => x.warehouseCode === selectedWarehouseCode)?.latitude;
    let selectedLongitude = warehouses.find(x => x.warehouseCode === selectedWarehouseCode)?.longitude;

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                setContainerSize({ width: Math.round(width), height: Math.round(height) });
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let markerImage = `${(process.env.REACT_APP_WEB_URL ?? "").replace(/^https:\/\//, 'http://')}/${process.env.REACT_APP_SUBDIRECTORY}/assets/pin-blue-small.png`;
    const markers = warehouses
        .map((warehouse) => {
            return `icon:${encodeURIComponent(markerImage)}|${warehouse.latitude},${warehouse.longitude}`;
        })
        .join("&markers=");

    const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?scale=2&center=${selectedLatitude},${(selectedLongitude ?? 0) + longitudeCenterOffset}&zoom=${10}&size=${containerSize.width}x${containerSize.height}&markers=${markers}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}`;

    return (
        <div ref={containerRef} style={{ textAlign: "center", height: "100%", backgroundColor: 'lightgray' }}>
            {containerSize.width > 0 && containerSize.height > 0 && selectedLatitude && selectedLongitude ? (
                <img
                    src={staticMapUrl}
                    alt="Map"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            ) : null}
        </div>
    );
};

export default StaticMapComponent;
