import React, { useEffect, useState } from 'react';
import BuilderIOPage from '../../builderio/components/builderIOPage';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import PageLoading from '../../components/shared/pageLoading';
import useImpactPrograms from '../../hooks/useImpactPrograms';

const ImpactPrograms: React.FC = () => {
    const { builderIOData, isLoading, stateChangedHandler } = useImpactPrograms();

    if (isLoading) {
        return <PageLoading />;
    }

    return (
        <BuilderIOPage
            data={builderIOData}
            onStateChange={stateChangedHandler}
            url="/impact-programs/impact-programs-overview" />
    );
};

export default ImpactPrograms;