import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useNavigate } from 'react-router-dom';

const useAppUrl = () => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state: RootState) => state.userState.isAuthenticated);

    const getAppUrl = useCallback((url: string): string => {
        const apiUrl = process.env.REACT_APP_WEB_URL!;
        return isAuthenticated ? `${apiUrl}${url}` : `${apiUrl}/quicklogin?returnUrl=${url}`;
    }, [isAuthenticated]);

    const appUrlNavigate = useCallback((url: string) => {
        let apiUrlAlgolia = '';
        if (url === `/gallery?searchRenaissance=true`) {
            apiUrlAlgolia = process.env.REACT_APP_WEB_URL + '/AlgoliaSearch/SearchRenaissance';
        }
        else if (url === `/gallery?searchWindows=true`) {
            apiUrlAlgolia = process.env.REACT_APP_WEB_URL + '/AlgoliaSearch/SearchWindows';
        }
        else {
            apiUrlAlgolia = process.env.REACT_APP_WEB_URL + url.replace('/gallery', '/AlgoliaSearch/SearchCategory');
        }
        return isAuthenticated ? document.location.href = `${apiUrlAlgolia}` : navigate(`${url}`);
    }, [isAuthenticated, navigate]);

    const appCategoryUrl = useCallback((url: string) : string => {
        const apiUrlAlgolia = process.env.REACT_APP_WEB_URL + url.replace('/gallery', '/AlgoliaSearch/SearchCategory');
        return isAuthenticated ? `${apiUrlAlgolia}` : `${url}`;
    }, [isAuthenticated]);

    const getAppGlobal = useCallback((url: string): string => {
        const apiUrl = process.env.REACT_APP_WEB_URL;
        return `${apiUrl}${url}`;
    }, []);

    return { getAppUrl, appUrlNavigate, appCategoryUrl, getAppGlobal };
};
export default useAppUrl;